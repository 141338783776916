import {
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
} from "@material-ui/core";
import {
  AccountCircleOutlined,
  PhoneInTalkOutlined,
  PhoneLockedOutlined,
} from "@material-ui/icons";

import AircallPhone from "aircall-everywhere";
import { useEffect, useState } from "react";
import ReactSimplyCarousel from "react-simply-carousel";

interface AirCallPhoneProps {
  showAirCallPhone: boolean;
  setShowAirCallPhone: any;
  phoneRef: any;
  hideStats?: boolean;
  killPadding?: boolean;
}

const AircallPhoneComponent = ({
  showAirCallPhone,
  setShowAirCallPhone,
  phoneRef,
  killPadding = false,
  hideStats = false,
}: AirCallPhoneProps) => {
  const [isPhoneLoaded, setIsPhoneLoaded] = useState(false);
  const [statusMessage, setStatusMessage] = useState("Aircall loading");
  // eslint-disable-next-line
  const [callLogs, setCallLogs] = useState([]);
  const [isAirCallReady, setIsAirCallReady] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const initializeAircall = () => {
    const phone = new AircallPhone({
      domToLoadPhone: "#phone",
      onLogin: (settings) => {
        setIsPhoneLoaded(true);

        handleCheckLogin();
        setIsAirCallReady(true);
        setStatusMessage("AirCall ready!");

        if (!settings?.user) {
          setIsAirCallReady(false);
          setStatusMessage("Login to Aircall");
        }
      },
      onLogout: () => {
        setStatusMessage("Aircall loading");
        setIsPhoneLoaded(false);
        setIsAirCallReady(false);
      },
    });

    phoneRef.current = phone;

    phone.on("incoming_call", handleIncomingCall);
    phone.on("call_end_ringtone", handleCallEndRingtone);
    phone.on("call_ended", handleCallEnded);
  };

  useEffect(() => {
    // Load Aircall script
    const script = document.createElement("script");
    script.src = "https://cdn.aircall.io/aircall-everywhere.min.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = initializeAircall;

    initializeAircall();

    return () => {
      document.body.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIncomingCall = (callInfo) => {
    const message = `Incoming call from ${callInfo.from} to ${callInfo.to} ringing!`;
    addCallLog("incoming_call", callInfo, message);
  };

  const handleCallEndRingtone = (callInfo) => {
    const message = `Ringing ended. Call was ${callInfo.answer_status}`;
    addCallLog("call_end_ringtone", callInfo, message);
  };

  const handleCallEnded = (callInfo) => {
    const message = `Call ended. Lasted ${callInfo.duration} seconds`;
    addCallLog("call_ended", callInfo, message);
  };

  const addCallLog = (id, payload, log) => {
    setCallLogs((prev) => [
      ...prev,
      { id, payload, log, time: new Date().toLocaleTimeString() },
    ]);
    setStatusMessage(log);
  };

  const handlePhoneShow = () => {
    setShowAirCallPhone(!showAirCallPhone);
  };

  const handleCheckLogin = () => {
    setIsAirCallReady(false);
    if (phoneRef.current) {
      phoneRef.current.isLoggedIn((response) => {
        setStatusMessage(response ? "Aircall ready" : "Aircall not ready");

        if (response) {
          setIsAirCallReady(true);
        }

        if (!response) {
          setStatusMessage("Login to Aircall");
        }
      });
    }
  };

  return (
    <Card variant="outlined" onClick={handleCheckLogin}>
      {!hideStats && (
        <>
          <CardContent
            className="flex-center"
            style={{
              minHeight: killPadding ? 25 : 50,
            }}
          >
            <ReactSimplyCarousel
              activeSlideIndex={activeSlideIndex}
              itemsToScroll={1}
              itemsToShow={1}
              onRequestChange={setActiveSlideIndex}
              forwardBtnProps={{
                style: {
                  display: "none",
                },
              }}
              backwardBtnProps={{
                style: {
                  display: "none",
                },
              }}
              containerProps={{
                style: {
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  userSelect: "none",
                },
              }}
              autoplayDelay={5000}
              speed={300}
              autoplay
            >
              <Typography
                variant="subtitle2"
                className="flex-center"
                style={{ minWidth: 200 }}
              >
                Total Call time:{" "}
                <Typography
                  style={{
                    color: "var(--primary)",
                    paddingLeft: 5,
                    fontWeight: "bold",
                  }}
                  variant="subtitle2"
                >
                  {callLogs}
                </Typography>
              </Typography>
              <Typography
                variant="subtitle2"
                className="flex-center"
                style={{ minWidth: 200 }}
              >
                Missed Calls:{" "}
                <Typography
                  style={{
                    color: "var(--primary)",
                    paddingLeft: 5,
                    fontWeight: "bold",
                  }}
                  variant="subtitle2"
                >
                  {callLogs}
                </Typography>
              </Typography>
              <Typography
                variant="subtitle2"
                className="flex-center"
                style={{ minWidth: 200 }}
              >
                Avg Call time:{" "}
                <Typography
                  style={{
                    color: "var(--primary)",
                    paddingLeft: 5,
                    fontWeight: "bold",
                  }}
                  variant="subtitle2"
                >
                  {"00:30M"}
                </Typography>
              </Typography>
            </ReactSimplyCarousel>
          </CardContent>
          <Divider />
        </>
      )}

      <CardContent
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant={"body2"}
          style={{
            fontWeight: "bold",
            transition: "cubic-bezier(0.075, 0.82, 0.165, 1)",
            color: isAirCallReady ? "var(--success)" : "var(--warning)",
          }}
        >
          {statusMessage}
        </Typography>
        {statusMessage === "Login to Aircall" && (
          <Button
            style={{
              marginLeft: "1rem",
              backgroundColor: showAirCallPhone
                ? "var(--error)"
                : "var(--primary)",
              color: "white",
              fontWeight: "bold",
              position: "absolute",
              right: "1rem",
            }}
            variant={"outlined"}
            onClick={handlePhoneShow}
          >
            <AccountCircleOutlined />
          </Button>
        )}
        {isPhoneLoaded && (
          <Button
            style={{
              marginLeft: "1rem",
              backgroundColor: showAirCallPhone
                ? "var(--error)"
                : "var(--primary)",
              color: "white",
              fontWeight: "bold",
              position: "absolute",
              right: "1rem",
            }}
            variant={"outlined"}
            onClick={handlePhoneShow}
          >
            {showAirCallPhone ? (
              <PhoneLockedOutlined />
            ) : (
              <PhoneInTalkOutlined />
            )}
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default AircallPhoneComponent;
