import { Grid, Tooltip, Typography } from "@material-ui/core";
import { PriorityHigh, Send } from "@material-ui/icons";
import AlternateEmail from "@material-ui/icons/AlternateEmail";
import Mail from "@material-ui/icons/MailOutline";
import Phone from "@material-ui/icons/PhoneOutlined";
import Sms from "@material-ui/icons/SmsOutlined";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import WhatsApp from "@material-ui/icons/WhatsApp";
import qs from "qs";
import * as React from "react";
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  CreateProps,
  DateTimeInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRefresh,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";
import { useHistory, useLocation } from "react-router-dom";
import {
  getReadableActivityType,
  getReadableSubjectByClaimAction,
} from "../api/activity/Activity";
import { ClaimTitle } from "../claim/ClaimTitle";
import { theme } from "../theme/theme";
import {
  ActivityResultsForDecisions,
  ActivityResultsForOutbound,
} from "../util/ClaimUtils";

let INITIAL_DATE: Date | undefined;

const CLAIM_ACTIONS = [
  {
    label: getReadableSubjectByClaimAction("FirstDunning"),
    value: "FirstDunning",
  },
  {
    label: getReadableSubjectByClaimAction("SecondDunning"),
    value: "SecondDunning",
  },
  {
    label: getReadableSubjectByClaimAction("ThirdDunning"),
    value: "ThirdDunning",
  },
  {
    label: getReadableSubjectByClaimAction("FourthDunning"),
    value: "FourthDunning",
  },
  {
    label: getReadableSubjectByClaimAction("FifthDunning"),
    value: "FifthDunning",
  },
  {
    label: getReadableSubjectByClaimAction("SixthDunning"),
    value: "SixthDunning",
  },
  {
    label: getReadableSubjectByClaimAction("FirstReminder"),
    value: "FirstReminder",
  },
  {
    label: getReadableSubjectByClaimAction("SecondReminder"),
    value: "SecondReminder",
  },
  {
    label: getReadableSubjectByClaimAction("ThirdReminder"),
    value: "ThirdReminder",
  },
  {
    label: getReadableSubjectByClaimAction("PhoneResearch"),
    value: "PhoneResearch",
  },
  {
    label: getReadableSubjectByClaimAction("EmailResearch"),
    value: "EmailResearch",
  },
  {
    label: getReadableSubjectByClaimAction("OnlineResearch"),
    value: "OnlineResearch",
  },
  {
    label: getReadableSubjectByClaimAction("PublicAuthorityRequest"),
    value: "PublicAuthorityRequest",
  },
  {
    label: getReadableSubjectByClaimAction("DefaultSummon"),
    value: "DefaultSummon",
  },
  {
    label: getReadableSubjectByClaimAction("Lawsuit"),
    value: "Lawsuit",
  },
  {
    label: getReadableSubjectByClaimAction("DebtCollectionTransfer"),
    value: "DebtCollectionTransfer",
  },
];

const ClaimActionInput = (): React.ReactElement => {
  const { values } = useFormState();
  let choices = CLAIM_ACTIONS;

  if (values.activityType) {
    if (values.activityType === "OutboundWhatsapp") {
      // whatsapp: only has 2nd and 3rd option
      choices = choices.filter(
        (choice) =>
          choice.value === "SecondDunning" || choice.value === "ThirdDunning"
      );
    } else if (values.activityType.startsWith("Outbound")) {
      choices = choices.filter(
        (choice) =>
          choice.value.endsWith("Dunning") || choice.value.endsWith("Reminder")
      );
    } else if (values.activityType === "Research") {
      choices = choices.filter(
        (choice) =>
          choice.value.endsWith("Research") ||
          choice.value === "PublicAuthorityRequest"
      );
    } else if (values.activityType === "JudicialActivity") {
      choices = choices.filter(
        (choice) =>
          choice.value === "DefaultSummon" || choice.value === "Lawsuit"
      );
    } else if (values.activityType === "InsolvencyActivity") {
      choices = choices.filter(
        (choice) => choice.value === "" // todo
      );
    } else if (values.activityType === "CreditorDecision") {
      choices = choices.filter(
        (choice) => choice.value === "DebtCollectionTransfer"
      );
    }
  }

  return (
    <SelectInput
      disabled={
        !values.activityType ||
        (!values.activityType?.startsWith("Outbound") &&
          values.activityType !== "Other" &&
          values.activityType !== "CreditorDecision" &&
          values.activityType !== "Research" &&
          !values.activityType?.endsWith("Activity"))
      }
      source="claimAction"
      label="Claim Action"
      choices={choices}
      optionText="label"
      allowEmpty
      optionValue="value"
    />
  );
};

const ActivityDateInput = () => {
  const { values } = useFormState();
  const today = new Date();

  if (!values.activityDate) {
    values.activityDate = today;
  } else if (values.activityDate && new Date(values.activityDate) > today) {
    values.isPlanned = true;
  }

  return (
    <DateTimeInput
      style={{ width: 320, marginRight: 20 }}
      required
      label="Activity Date"
      source="activityDate"
    />
  );
};

const CommentInput = (props: any) => {
  const { values } = useFormState();

  return (
    <>
      <TextInput
        label="Comment"
        multiline
        defaultValue={props.placeholderComment}
        minRows={5}
        onChange={(e) => {
          if (props.setPlaceholderComment) {
            props.setPlaceholderComment(e.target.value);
          }
        }}
        fullWidth
        source="comment"
      />
      {values.activityType === "InternalFeedback" && (
        <Typography
          variant="subtitle2"
          style={{
            color: theme.palette.error.dark,
          }}
        >
          <ul>
            <li>Situation, Komplikation und Frage sehr kurz formulieren!</li>
            <li>Müssen wir zwingend den Gläubiger involvieren?</li>
            <li>
              Ist eine klare Frage und genug Kontext inkludiert? (Gläubiger sind
              keine Experten)
            </li>
          </ul>
        </Typography>
      )}
      {["OutboundMail", "OutboundLetter"].includes(values.activityType) &&
        !values.claimAction &&
        !values.isPlanned &&
        values.comment?.length > 0 && (
          <Typography
            variant="subtitle2"
            style={{
              color: theme.palette.error.dark,
            }}
          >
            <ul>
              <li>
                <b>Vorsicht:</b> Speichern verschickt die Nachricht an den
                Schuldner!
              </li>
            </ul>
          </Typography>
        )}
    </>
  );
};

const IsPlannedInput = (): React.ReactElement => {
  const form = useForm();

  return (
    <BooleanInput
      label="Is Planned"
      source="isPlanned"
      style={{
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
      }}
      onChange={(value) => {
        if (!value) {
          // update to current date
          form.change("activityDate", new Date());
        } else if (INITIAL_DATE) {
          form.change("activityDate", INITIAL_DATE);
        }
      }}
    />
  );
};

const ResultInput = () => {
  const { values } = useFormState();
  const form = useForm();

  return (
    <SelectInput
      source="result"
      label="Result"
      onChange={(e) => {
        if (e.target.value) {
          form.change("activityDate", new Date());
          form.change("isPlanned", false);
        }
      }}
      choices={
        !values.activityType ||
        values.activityType?.startsWith("Outbound") ||
        values.activityType === "Response"
          ? ActivityResultsForOutbound
          : ActivityResultsForDecisions
      }
      optionText="label"
      allowEmpty
      optionValue="value"
    />
  );
};

const ActivityTypeInput = () => {
  const form = useForm();

  return (
    <SelectInput
      required
      onChange={(e) => {
        if (
          e.target?.value?.startsWith("Outbound") ||
          e.target?.value?.endsWith("Decision")
        ) {
          form.change("isPlanned", true);
        } else {
          form.change("isPlanned", false);
        }
      }}
      source="activityType"
      label="Activity Type"
      choices={getFormattedActivityTypeChoices()}
      optionText="label"
      optionValue="value"
    />
  );
};

export const ActivityCreate = (props: CreateProps): React.ReactElement => {
  const parsedLocation = qs.parse(useLocation().search.slice(1));
  const history = useHistory();
  const notify = useNotify();
  const refresh = useRefresh();

  return (
    <Create
      className={`activity-create ${props.options?.inline ? "-inline" : ""}`}
      {...props}
      onSuccess={() => {
        if (props.options?.setPlaceholderComment) {
          props.options.setPlaceholderComment("");
        }
        notify("Success", "info", null, true);
        if (props.options?.inline) {
          refresh();
          return;
        }
        history.goBack();
      }}
    >
      <SimpleForm>
        <Grid container spacing={2} style={{ width: "100%" }}>
          <Grid item xs={12}>
            <ActivityDateInput />
            <div
              style={{
                display: "inline-block",
                paddingTop: "0.75rem",
                paddingLeft: "0.5rem",
              }}
            >
              <IsPlannedInput />
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <ActivityTypeInput />
          </Grid>
          <Grid item xs={12} md={4}>
            <ClaimActionInput />
          </Grid>
          <Grid item xs={12} md={4}>
            <ResultInput />
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            style={
              (parsedLocation.claimId || props.options?.claimId) && {
                display: "none",
              }
            }
          >
            <ReferenceInput
              filterToQuery={(searchText) => ({
                reference: { startsWith: searchText },
              })}
              isRequired
              source="claim.id"
              defaultValue={parsedLocation.claimId || props.options?.claimId}
              reference="Claim"
              label="Claim"
            >
              <AutocompleteInput suggestionLimit={5} optionText={ClaimTitle} />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12}>
            <CommentInput
              placeholderComment={props.options?.placeholderComment}
              setPlaceholderComment={props.options?.setPlaceholderComment}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export function getFormattedActivityTypeChoices(): object[] {
  return [
    {
      value: "outbound-header",
      label: (
        <span style={{ fontWeight: 600, fontSize: 11 }}>{"Ausgehend"}</span>
      ),
      disabled: true,
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          <Phone
            style={{
              width: "auto",
              height: 15,
              verticalAlign: "middle",
              marginRight: 8,
              marginBottom: 2,
            }}
          />
          {getReadableActivityType("OutboundCall")}
          <Tooltip title="Teil der Mahnstrecke">
            <Send
              style={{
                position: "absolute",
                right: 16,
                color: theme.palette.secondary.main,
                opacity: 0.7,
              }}
            />
          </Tooltip>
        </span>
      ),
      value: "OutboundCall",
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          <AlternateEmail
            style={{
              width: "auto",
              height: 15,
              verticalAlign: "middle",
              marginRight: 8,
              marginBottom: 2,
            }}
          />
          {getReadableActivityType("OutboundMail")}
          <Tooltip title="Teil der Mahnstrecke">
            <Send
              style={{
                position: "absolute",
                right: 16,
                color: theme.palette.secondary.main,
                opacity: 0.7,
              }}
            />
          </Tooltip>
        </span>
      ),
      value: "OutboundMail",
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          <Mail
            style={{
              width: "auto",
              height: 15,
              verticalAlign: "middle",
              marginRight: 8,
              marginBottom: 2,
            }}
          />
          {getReadableActivityType("OutboundLetter")}
          <Tooltip title="Teil der Mahnstrecke">
            <Send
              style={{
                position: "absolute",
                right: 16,
                color: theme.palette.secondary.main,
                opacity: 0.7,
              }}
            />
          </Tooltip>
        </span>
      ),
      value: "OutboundLetter",
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          <WhatsApp
            style={{
              width: "auto",
              height: 15,
              verticalAlign: "middle",
              marginRight: 8,
              marginBottom: 2,
            }}
          />
          {getReadableActivityType("OutboundWhatsapp")}
          <Tooltip title="Teil der Mahnstrecke">
            <Send
              style={{
                position: "absolute",
                right: 16,
                color: theme.palette.secondary.main,
                opacity: 0.7,
              }}
            />
          </Tooltip>
        </span>
      ),
      value: "OutboundWhatsapp",
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          <Sms
            style={{
              width: "auto",
              height: 15,
              verticalAlign: "middle",
              marginRight: 8,
              marginBottom: 2,
            }}
          />
          {getReadableActivityType("OutboundSms")}
          <Tooltip title="Teil der Mahnstrecke">
            <Send
              style={{
                position: "absolute",
                right: 16,
                color: theme.palette.secondary.main,
                opacity: 0.7,
              }}
            />
          </Tooltip>
        </span>
      ),
      value: "OutboundSms",
    },
    {
      value: "creditor-header",
      label: (
        <span style={{ fontWeight: 600, fontSize: 11 }}>
          {"Gläubiger-Kommunikation"}
        </span>
      ),
      disabled: true,
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          {getReadableActivityType("InternalFeedback")}
          <Tooltip title='Setzt Akte auf "Feedback Required"'>
            <PriorityHigh
              style={{
                position: "absolute",
                right: 16,
                color: theme.palette.error.light,
              }}
            />
          </Tooltip>
        </span>
      ),
      value: "InternalFeedback",
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          {getReadableActivityType("CreditorDecision")}
          <Tooltip title='Setzt Akte auf "Feedback Required"'>
            <PriorityHigh
              style={{
                position: "absolute",
                right: 16,
                color: theme.palette.error.light,
              }}
            />
          </Tooltip>
        </span>
      ),
      value: "CreditorDecision",
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          {getReadableActivityType("CreditorInformation")}
        </span>
      ),
      value: "CreditorInformation",
    },
    {
      label: (
        <span
          style={{
            paddingLeft: 4,
            fontStyle: "italic",
            fontSize: 15,
            color: theme.palette.grey[600],
          }}
        >
          {getReadableActivityType("CreditorFeedback")}
        </span>
      ),
      value: "CreditorFeedback",
    },
    {
      value: "internal-header",
      label: (
        <span style={{ fontWeight: 600, fontSize: 11 }}>{"Internes"}</span>
      ),
      disabled: true,
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          {getReadableActivityType("InternalDecision")}
          <Tooltip title="Nicht sichtbar für Gläubiger">
            <VisibilityOff
              style={{
                position: "absolute",
                right: 12,
                width: 20,
                color: theme.palette.grey[400],
              }}
            />
          </Tooltip>
        </span>
      ),
      value: "InternalDecision",
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          {getReadableActivityType("Research")}
        </span>
      ),
      value: "Research",
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          {getReadableActivityType("JudicialActivity")}
        </span>
      ),
      value: "JudicialActivity",
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          {getReadableActivityType("InsolvencyActivity")}
        </span>
      ),
      value: "InsolvencyActivity",
    },
    {
      label: (
        <span style={{ paddingLeft: 4, fontSize: 15 }}>
          {getReadableActivityType("Other")}
        </span>
      ),
      value: "Other",
    },
    {
      label: (
        <span
          style={{
            paddingLeft: 4,
            fontStyle: "italic",
            fontSize: 15,
            color: theme.palette.grey[600],
          }}
        >
          {getReadableActivityType("Response")}
        </span>
      ),
      value: "Response",
    },
    {
      label: (
        <span
          style={{
            paddingLeft: 4,
            fontStyle: "italic",
            fontSize: 15,
            color: theme.palette.grey[600],
          }}
        >
          {getReadableActivityType("RecommendedOutboundMail")}
          <Tooltip title="Nicht sichtbar für Gläubiger">
            <VisibilityOff
              style={{
                position: "absolute",
                right: 12,
                width: 20,
                color: theme.palette.grey[400],
              }}
            />
          </Tooltip>
        </span>
      ),
      value: "RecommendedOutboundMail",
    },
    {
      label: (
        <span
          style={{
            paddingLeft: 4,
            fontStyle: "italic",
            fontSize: 15,
            color: theme.palette.grey[600],
          }}
        >
          {getReadableActivityType("RecommendedInternalFeedback")}
          <Tooltip title="Nicht sichtbar für Gläubiger">
            <VisibilityOff
              style={{
                position: "absolute",
                right: 12,
                width: 20,
                color: theme.palette.grey[400],
              }}
            />
          </Tooltip>
        </span>
      ),
      value: "RecommendedInternalFeedback",
    },
  ];
}
