import {
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { CloudDownloadOutlined, CloudUploadOutlined } from "@material-ui/icons";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Button, LoadingIndicator } from "react-admin";
import { EnumPaymentPaymentType } from "../../../api/payment/EnumPaymentPaymentType";
import { theme } from "../../../theme/theme";
import { numberFormatEUR } from "../../../util/CurrencyUtil";

type AdditionalCostCardProps = {
  payments: any;
  paymentDataLoaded: any;
  record: any;
  creditor: any;
  creditorAddress: any;
};

const AdditionalCostCard = ({
  payments,
  paymentDataLoaded,
  record,
  creditor,
  creditorAddress,
}: AdditionalCostCardProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    if (paymentDataLoaded) {
      setIsLoading(false);
    }
  }, [paymentDataLoaded]);

  const thirdPartyOutboundPayments = payments.filter(
    (payment) =>
      payment.paymentType ===
      EnumPaymentPaymentType.DebtCollectorOutboundPosition
  );

  const thirdPartyInboundPayments = payments.filter(
    (payment) =>
      payment.paymentType ===
      EnumPaymentPaymentType.DebtCollectorInboundPosition
  );

  const thirdPartyInboundPaidPayments = thirdPartyInboundPayments.filter(
    (payment) => payment.isPlanned === false
  );

  let thirdPartyInboundPaidPaymentsTotal = parseFloat(
    Math.abs(
      thirdPartyInboundPaidPayments.reduce(function (a, b) {
        if (b.isTaxFree) {
          return a + parseFloat((b.amount * 1.19).toFixed(2));
        }
        return a + b.amount;
      }, 0)
    )?.toFixed(2)
  );

  const thirdPartyPaymentsTotal = parseFloat(
    (
      parseFloat(
        Math.abs(
          thirdPartyOutboundPayments.reduce(function (a, b) {
            return a + b.amount;
          }, 0)
        )?.toFixed(2)
      ) - thirdPartyInboundPaidPaymentsTotal
    )?.toFixed(2)
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Card>
      <CardContent>
        <Typography
          variant="h6"
          style={thirdPartyPaymentsTotal > 0 ? { color: "red" } : {}}
        >
          Nebenkosten{thirdPartyPaymentsTotal > 0 && " (Offen!)"}
        </Typography>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <Button
          href={
            "/#/Payment/create?claimId=" +
            record?.id +
            "&paymentType=DebtCollectorOutboundPosition"
          }
          startIcon={<CloudDownloadOutlined />}
          style={{
            backgroundColor: theme.palette.grey[600],
            fontSize: 12,
            marginTop: 10,
            marginBottom: 10,
            minWidth: "100%",
          }}
          size={"medium"}
          label="Eingehende Rechnung"
          variant="contained"
        />
        <Button
          href={
            "/#/Payment/create?claimId=" +
            record?.id +
            "&paymentType=DebtCollectorInboundPosition"
          }
          startIcon={<CloudUploadOutlined />}
          style={{
            backgroundColor: theme.palette.grey[800],
            fontSize: 12,
            minWidth: "100%",
            marginBottom: 20,
          }}
          size={"medium"}
          label="Ausgehende Rechnung"
          variant="contained"
        />
        {thirdPartyOutboundPayments?.length > 0 && (
          <List
            subheader={
              <Typography
                variant="body2"
                style={{ color: "red", fontWeight: "bold" }}
              >
                Eingehende Rechnungen:
              </Typography>
            }
          >
            {thirdPartyOutboundPayments.map((payment, index) => (
              <>
                <ListItem key={index} alignItems="flex-start">
                  <ListItemText
                    primary={
                      <Typography variant={"h6"}>
                        {`${payment.reference} ${
                          payment.isPlanned ? "| (offen)" : ""
                        }`}
                      </Typography>
                    }
                    secondary={
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant={"subtitle2"}>
                          {format(new Date(payment.paymentDate), "dd-MM-yyyy")}
                        </Typography>
                        <Typography variant={"subtitle2"}>
                          {numberFormatEUR.format(payment.amount)}
                        </Typography>
                      </div>
                    }
                  ></ListItemText>
                </ListItem>
                <Divider variant="fullWidth" component="li" />
              </>
            ))}
          </List>
        )}
        {thirdPartyInboundPayments?.length > 0 && (
          <Typography variant="body2" style={{ color: "green" }}>
            Ausgehende Rechnungen:{" "}
            <ul>
              {thirdPartyInboundPayments.map((payment, index) => {
                let amount = payment.amount;
                if (
                  creditorAddress?.country === "DEU" &&
                  creditor.taxDeductionAllowed &&
                  !payment.isTaxFree
                ) {
                  amount = parseFloat((amount * 1.19)?.toFixed(2));
                }

                return (
                  <li key={index}>
                    <>{format(new Date(payment.paymentDate), "dd-MM-yyyy")}:</>{" "}
                    <b>{numberFormatEUR.format(amount)}</b>
                    <br />
                    {payment.reference}
                    {payment.isPlanned && " (offen)"}
                  </li>
                );
              })}
            </ul>
          </Typography>
        )}
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body2"
            style={{
              fontWeight: 600,
            }}
          >
            Offene Summe:{" "}
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontWeight: 600,
            }}
          >
            {numberFormatEUR.format(Math.max(0, thirdPartyPaymentsTotal))}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default AdditionalCostCard;
