import * as React from "react";

import {
  Badge,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Input,
  ListItem,
  ListItemText,
  List as MuiList,
  Tooltip,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import {
  AppsOutlined,
  CallOutlined,
  ChatOutlined,
  CommentOutlined,
  Event,
  Gavel,
  Help,
  Mail,
  MoneyOff,
  Notifications,
  PaymentOutlined,
  PersonAdd,
  PersonAddOutlined,
  RemoveCircle,
  VerifiedUser,
  Visibility,
} from "@material-ui/icons";
import Add from "@material-ui/icons/Add";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import CloseRounded from "@material-ui/icons/CloseRounded";
import FileCopyOutlined from "@material-ui/icons/FileCopyOutlined";
import FolderOpenOutlined from "@material-ui/icons/FolderOpenOutlined";
import { default as PaymentIcon } from "@material-ui/icons/Payment";
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import {
  BooleanField,
  Button,
  DataProvider,
  Datagrid,
  DateField,
  ExportButton,
  FunctionField,
  List,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  RichTextField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  Title,
  TopToolbar,
  useDataProvider,
  useGetIdentity,
  useGetList,
  useGetOne,
  useListContext,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import ReactCountryFlag from "react-country-flag";
import { FileIcon, defaultStyles } from "react-file-icon";
import { io } from "socket.io-client";
import ActivityEventItemView, {
  ActivityComment,
} from "../Components/ActivityEventItem";
import { ClaimActivityTools } from "../Components/ClaimActivityTools";
import { ClaimDrawer } from "../Components/ClaimDrawer";
import { ClaimHeaderBar } from "../Components/ClaimHeaderBar";
import { PhoneNumberComponent } from "../Components/ClaimPhoneNumbers";
import { ClaimShowSidebar } from "../Components/ClaimShowSidebar";
import { useTitle } from "../Components/CustomHooks";
import AircallPhoneComponent from "../Components/aircall/AirCallPhone";
import { ActivityCreate } from "../activity/ActivityCreate";
import {
  Activity,
  getReadableActivityType,
  getReadableSubjectByClaimAction,
} from "../api/activity/Activity";
import {
  ADDRESS_COUNTRY_READABLE,
  ADDRESS_COUNTRY_SHORT,
  normalizePhoneNumber,
} from "../api/address/Address";
import { EnumAddressCountry } from "../api/address/EnumAddressCountry";
import { Claim } from "../api/claim/Claim";
import {
  EnumPaymentPaymentType,
  NEGATIVE_PAYMENT_TYPES,
  THIRD_PARTY_PAYMENT_TYPES,
} from "../api/payment/EnumPaymentPaymentType";
import { Payment } from "../api/payment/Payment";
import { PaymentPlan } from "../api/paymentPlan/PaymentPlan";
import { getReadableUserType } from "../api/user/User";
import { FINANCE_MANAGERS, VIP_USERS } from "../config/AppConfig";
import { theme } from "../theme/theme";
import { UserBusinessName, UserEmail } from "../user/UserShow";
import { DATE_REGEX } from "../util/ClaimUtils";
import { numberFormatEUR } from "../util/CurrencyUtil";
import { parseDate } from "../util/DateUtils";
import { searchMapByKey } from "../util/MapUtils";

export const ClaimShow = (props: ShowProps): React.ReactElement => {
  const { identity } = useGetIdentity();
  const [activeUser, setActiveUser] = useState(null);
  const claimId = props?.id;
  const userId = identity?.id;

  const handleKeyPress = React.useCallback(
    (event) => {
      if (event.target === document.body && event.key === "e") {
        window.open("/#/Claim/" + props?.id + "/edit", "_self");
      }

      if (event.target === document.body && event.key === "x") {
        window.open("/#/Claim/" + props?.id + "/edit", "_self");
      }
    },
    [props?.id]
  );

  React.useEffect(() => {
    // Attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // Remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    if (!claimId || !userId) return;

    const socket = io(`${process.env.REACT_APP_SERVER_URL}/claimsStatus`, {
      withCredentials: true,
      transports: ["websocket"],
    });

    socket.on("connect", () => {
      socket.emit("userJoinsClaim", { claimId, userId });
    });

    socket.on("userJoinsClaim", (data) => {
      socket.emit("userJoinsClaim", { claimId, userId });
      setActiveUser(data.userId);
    });

    return () => {
      socket.emit("userLeavesClaim", claimId);
      socket.disconnect();
    };
  }, [claimId, userId]);

  const enterClaim = async (claimId, userId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/claims/${claimId}/enter`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId }),
        }
      );

      const data = await response.json();
      console.log("Entered claim:", data);
    } catch (error) {
      console.error("Error entering claim:", error);
    }
  };

  React.useEffect(() => {
    if (activeUser) {
      enterClaim(claimId, userId);
    }
  }, [activeUser, claimId, userId]);

  const [absoluteTotal, setAbsoluteTotal] = React.useState(0);
  const [
    currentCreditorAmountDueInclInterest,
    setCurrentCreditorAmountDueInclInterest,
  ] = React.useState(0);

  const aside = React.useMemo(
    () => (
      <ClaimShowSidebar
        claimId={props?.id}
        setAbsoluteTotal={setAbsoluteTotal}
        setCurrentCreditorAmountDueInclInterest={
          setCurrentCreditorAmountDueInclInterest
        }
      />
    ),
    [props?.id, setAbsoluteTotal, setCurrentCreditorAmountDueInclInterest]
  );

  return (
    <Show {...props} title="Claim " hasEdit={false} aside={aside}>
      <ClaimTabs
        absoluteTotal={absoluteTotal}
        currentCreditorAmountDueInclInterest={
          currentCreditorAmountDueInclInterest
        }
      />
    </Show>
  );
};

const PaymentListItems = (props: any) => {
  const listContext = useListContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const { identity } = useGetIdentity();
  const uniquePaymentTypes = new Map<string, string>();

  const numberFormat = Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: props.claim?.currency || "EUR",
  });

  const enableDeletion = FINANCE_MANAGERS.includes(
    identity?.username.toString()
  );

  return (
    <>
      <Datagrid
        optimized
        hasBulkActions
        selectedIds={listContext.selectedIds}
        rowClick="edit"
        isRowSelectable={() => enableDeletion}
        isRowExpandable={(record) => record?.reference}
        rowStyle={(record) => {
          const values = Array.from(uniquePaymentTypes.values()) as string[];

          if (
            values.includes(record?.id?.toString()) ||
            (!NEGATIVE_PAYMENT_TYPES.includes(record?.paymentType) &&
              !THIRD_PARTY_PAYMENT_TYPES.includes(record?.paymentType) &&
              record?.paymentType !== "ClaimPosition" &&
              record?.paymentType !== "Payout" &&
              !uniquePaymentTypes.has(record?.paymentType))
          ) {
            uniquePaymentTypes.set(record?.paymentType, record?.id?.toString());
            return {
              backgroundColor: "#ffebeb",
            };
          }
        }}
      >
        <DateField
          source="paymentDate"
          label="Payment Date"
          locales="de-DE"
          options={{
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            timeZone: "Europe/Berlin",
          }}
        />
        <TextField source="paymentType" label="Type" />
        <FunctionField
          label="Amount"
          render={(record: any) => (
            <span
              style={
                record?.paymentType === "Payout"
                  ? { color: theme.palette.secondary.main, fontWeight: 600 }
                  : record?.paymentType === "ClaimPosition"
                  ? { color: theme.palette.primary.dark, fontWeight: 600 }
                  : record?.amount < 0
                  ? record?.paymentType === "ExistingPayments"
                    ? { color: "#ac7638", fontWeight: 600 }
                    : { color: "green", fontWeight: 600 }
                  : { color: "red", fontWeight: 600 }
              }
            >
              {["ExistingPayments", "ClaimPosition"].includes(
                record.paymentType as EnumPaymentPaymentType
              )
                ? numberFormat.format(record?.amount || 0)
                : numberFormatEUR.format(record?.amount || 0)}
            </span>
          )}
        />
        <TextField label="Reference" source="reference" />
        <BooleanField label="Is Planned" source="isPlanned" />
      </Datagrid>
      {!!listContext.selectedIds && !!listContext.selectedIds.length && (
        <Button
          variant="outlined"
          style={{ marginTop: 20 }}
          onClick={async () => {
            for (const selectedId of listContext.selectedIds) {
              await dataProvider.delete("Payment", {
                id: selectedId,
              });
            }
            listContext.onUnselectItems();
            refresh(true);
          }}
          label="Delete"
        />
      )}
    </>
  );
};

const ActivityListItems = () => {
  const listContext = useListContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const ActivityExportButton = () => {
    const { data } = useListContext();

    const activities: Activity[] = Object.values(data) as Activity[];

    const downloadCSV = (filename: string, data: Activity[]) => {
      // Logic to convert activities data to CSV format and initiate download
      // Example logic:
      const csvData = activities
        .map(
          (activity) =>
            `${activity.activityDate || ""},${activity.id || ""},${
              getReadableActivityType(activity.activityType) || ""
            },${activity.claimAction || ""},${activity.comment || ""}`
        )
        .join("\n");
      const csvHeader = "Date, ID, Activity Type, Action, Comment";
      const csvWithHeader = `${csvHeader}\n${csvData}`;

      const blob = new Blob([csvWithHeader], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
    };

    return (
      <ExportButton
        style={{ marginTop: 10, float: "right" }}
        onClick={() => downloadCSV(`activities.csv`, activities)}
      />
    );
  };

  return (
    <>
      <Datagrid
        optimized
        hasBulkActions
        selectedIds={listContext.selectedIds}
        rowStyle={(record) => {
          if (
            record?.activityType?.startsWith("Recommended") &&
            !record?.isPlanned
          ) {
            return {
              display: "none",
            };
          }

          if (record?.isPlanned) {
            return {
              backgroundColor: "#ebfaff",
            };
          }
        }}
        isRowSelectable={(record) => record?.isPlanned}
        rowClick="edit"
        style={{
          overflow: "hidden",
          overflowX: "auto",
        }}
      >
        <ActivityEventItemView />
        <ActivityComment />
      </Datagrid>
      {!!listContext.selectedIds && !!listContext.selectedIds.length && (
        <Button
          variant="outlined"
          style={{ marginTop: 20 }}
          onClick={async () => {
            for (const selectedId of listContext.selectedIds) {
              await dataProvider.delete("Activity", {
                id: selectedId,
              });
            }
            listContext.onUnselectItems();
            refresh(true);
          }}
          label="Delete"
        />
      )}
      <ActivityExportButton />
    </>
  );
};

const renderFiles = (
  files: string[],
  allFiles: string[],
  record: Claim,
  notify: any,
  dataProvider: DataProvider,
  handleFileClick: any,
  setIsLoading: any
) =>
  files.map((url: string) => {
    let fileTitle = url
      .replace("media/", "")
      .replace(record?.reference + "/", "")
      .replace(record?.reference, "");

    const splitFileTitle = fileTitle.split(".");
    let fileDate;
    const fileExtension = splitFileTitle.pop();
    fileTitle = splitFileTitle.shift();

    const dateMatch = fileTitle?.match(DATE_REGEX);
    if (dateMatch) {
      fileDate = parseDate(dateMatch[1]);
      fileTitle = fileTitle
        .replace(DATE_REGEX, "")
        .replace(/^-/, "")
        .replace("--", "-")
        .replace(/^-/, "")
        .replace("--", "-")
        .replace(/^[\w]{2}-/, "")
        .replace(/-[\w]{2}$/, "")
        .trim()
        .replace(/ -$/, "")
        .replace(/-$/, "");
    }

    return (
      <Card
        key={url}
        variant="outlined"
        style={{ background: theme.palette.grey[100] }}
      >
        <CardActionArea
          onClick={() => handleFileClick(record, url, false, setIsLoading)}
        >
          <Grid container style={{ alignItems: "center" }}>
            <Grid
              xs={1}
              item
              style={{
                padding: 14,
                cursor: "pointer",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div
                style={{
                  height: 30,
                  width: 30,
                  margin: "0 auto",
                  marginTop: -5,
                }}
              >
                <FileIcon
                  labelUppercase
                  color={
                    !url.toLowerCase().includes("dunning") &&
                    !url.toLowerCase().includes("courtprocessintro") &&
                    !url.toLowerCase().includes("monitoring") &&
                    !url.toLowerCase().includes("paymentplan")
                      ? theme.palette.grey[300]
                      : theme.palette.secondary.main
                  }
                  extension={fileExtension}
                  {...defaultStyles[fileExtension]}
                />
              </div>
            </Grid>
            <Grid
              xs={4}
              lg={6}
              item
              style={{
                fontWeight: 600,
                fontSize: 14,
                paddingBottom: 15,
                paddingTop: 15,
                position: "relative",
              }}
            >
              {fileTitle || url}
            </Grid>
            <Grid
              xs={3}
              lg={2}
              item
              style={{
                padding: 15,
                color: theme.palette.grey[700],
                fontSize: 14,
                textAlign: "center",
              }}
            >
              {fileDate && <>{format(fileDate, "dd.MM.yyyy")}</>}
            </Grid>
            <Grid
              xs={4}
              lg={3}
              item
              style={{ textAlign: "right", position: "relative" }}
            >
              <CloseRounded
                style={{
                  height: 16,
                  cursor: "pointer",
                  color: theme.palette.error.main,
                  padding: 15,
                  right: 90,
                  top: -22,
                  verticalAlign: "middle",
                  position: "absolute",
                  zIndex: 3,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (
                    window.confirm("Do you really want to remove this file?")
                  ) {
                    dataProvider.update("Claim", {
                      id: record.id,
                      data: {
                        fileUrls: JSON.stringify(
                          allFiles.filter((parsedUrl) => parsedUrl !== url)
                        ),
                      },
                      previousData: record,
                    });
                  }
                }}
              />
              <Tooltip title="Copy file name">
                <FileCopyOutlined
                  style={{
                    height: 16,
                    cursor: "copy",
                    padding: 15,
                    right: 45,
                    top: -22,
                    verticalAlign: "middle",
                    position: "absolute",
                    zIndex: 3,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(fileTitle);
                    notify("Copied to clipboard", "info", null, false);
                  }}
                />
              </Tooltip>
              <Tooltip title="Copy path">
                <FolderOpenOutlined
                  style={{
                    height: 16,
                    cursor: "copy",
                    padding: 15,
                    right: 0,
                    top: -22,
                    verticalAlign: "middle",
                    position: "absolute",
                    zIndex: 3,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(url);
                    notify("Copied to clipboard", "info", null, false);
                  }}
                />
              </Tooltip>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    );
  });

const FileList = (): React.ReactElement => {
  let record: Claim = useRecordContext();
  let [internalFiles, setInternalFiles] = React.useState<string[]>([]);
  let [externalFiles, setExternalFiles] = React.useState<string[]>([]);
  let [allFiles, setAllFiles] = React.useState<string[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const refresh = useRefresh();
  const notify = useNotify();

  React.useEffect(() => {
    if (record?.fileUrls) {
      try {
        const urls = JSON.parse(record?.fileUrls as string);

        const matchingUrls = [];
        const nonMatchingUrls = [];

        const conditions = (url: string) =>
          !url.toLowerCase().includes("dunning") &&
          !url.toLowerCase().includes("courtprocessintro") &&
          !url.toLowerCase().includes("monitoring") &&
          !url.toLowerCase().includes("paymentplan");

        urls.forEach((url: string) => {
          if (conditions(url)) {
            nonMatchingUrls.push(url);
          } else {
            matchingUrls.push(url);
          }
        });

        setInternalFiles(matchingUrls);
        setExternalFiles(nonMatchingUrls);
        setAllFiles(urls);
      } catch (e) {
        // Handle error if necessary
      }
    }
  }, [record?.fileUrls]);

  const uploadProps = {
    name: "file",
    onChange: async (event: React.ChangeEvent<HTMLInputElement>) => {
      const authHeader = {
        headers: { Authorization: localStorage.getItem("credentials") || "" },
      };

      const files = event.target.files;

      if (files && files.length > 0) {
        try {
          for (const file of Array.from(files)) {
            const formData = new FormData();
            formData.append("file", file);

            await fetch(
              process.env.REACT_APP_SERVER_URL +
                "/api/claims/" +
                record?.id +
                "/files",
              {
                method: "POST",
                body: formData,
                ...authHeader,
              }
            );

            // Optional: You can add a delay between each request
            await new Promise((resolve) => setTimeout(resolve, 250));
          }

          notify("Uploaded successfully", "success", null, false);
          refresh();
        } catch (error) {
          notify("Upload failed", "error", null, false);
        }
      }
    },
  };

  function handleFileClick(
    record: Claim,
    url: string,
    saveFile?: boolean,
    setIsLoading?: any
  ): void {
    const authHeader = {
      headers: { Authorization: localStorage.getItem("credentials") || "" },
    };

    setIsLoading(true);
    let urlToFetch = url
      .split("media/" + record?.reference?.replaceAll("/", "-") + "/")
      .pop();
    urlToFetch = encodeURIComponent(urlToFetch || "");

    if (urlToFetch) {
      fetch(
        process.env.REACT_APP_SERVER_URL +
          "/api/claims/" +
          record?.reference?.replaceAll("/", "-") +
          "/file/" +
          urlToFetch,
        authHeader
      ).then((response) => {
        if (response.ok) {
          let anchor = document.createElement("a");
          document.body.appendChild(anchor);

          response.blob().then((blobby) => {
            const objectUrl = window.URL.createObjectURL(blobby);
            anchor.href = objectUrl;
            anchor.download = urlToFetch;
            anchor.target = "_blank";
            anchor.click();

            if (!saveFile) {
              window.open(objectUrl, "_blank"); // Open the file in a new tab
            }

            window.URL.revokeObjectURL(objectUrl);
            notify("File downloaded", "info", null, false);
          });
        }

        setIsLoading(false);
      });
    }
  }

  const useStyles = makeStyles((theme) => ({
    fileInput: {
      margin: "10px 0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "2px dashed #ccc",
      borderRadius: "5px",
      cursor: "pointer",
      transition: "border-color 0.3s",
      "&:hover": {
        borderColor: "#999",
      },
    },
  }));
  const classes = useStyles();

  const dataProvider = useDataProvider();
  return (
    <>
      <div>
        <label htmlFor="file" className={classes.fileInput}>
          <Input
            id="file"
            name="file"
            type="file"
            style={{ width: "100%", padding: 20 }}
            inputProps={{ multiple: true }}
            {...uploadProps}
          />
        </label>
      </div>
      <br />
      <Divider />
      <br />
      <Typography variant="h5">
        Files {isLoading && <CircularProgress size={24} />}
      </Typography>
      <>
        {externalFiles && externalFiles.length > 0 && (
          <>
            <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
              Main Files
            </Typography>
            {renderFiles(
              externalFiles,
              allFiles,
              record,
              notify,
              dataProvider,
              handleFileClick,
              setIsLoading
            )}
            <Button
              variant="text"
              size="medium"
              style={{ marginTop: 10 }}
              startIcon={<ArrowDownward />}
              onClick={() =>
                externalFiles.map((url) =>
                  handleFileClick(record, url, true, setIsLoading)
                )
              }
              label="Download all main files"
            />
            <br />
            <br />
          </>
        )}

        {internalFiles && internalFiles.length > 0 && (
          <>
            <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
              Generated Files
            </Typography>
            {renderFiles(
              internalFiles,
              allFiles,
              record,
              notify,
              dataProvider,
              handleFileClick,
              setIsLoading
            )}
            <Button
              variant="text"
              size="medium"
              style={{ marginTop: 10 }}
              startIcon={<ArrowDownward />}
              onClick={() =>
                internalFiles.map((url) =>
                  handleFileClick(record, url, true, setIsLoading)
                )
              }
              label="Download all generated files"
            />
            <br />
            <br />
          </>
        )}
      </>
    </>
  );
};

const PaymentList = ({
  paymentPlans,
  absoluteTotal,
  currentCreditorAmountDueInclInterest,
}: any): React.ReactElement => {
  const claim = useRecordContext();
  const useRates = paymentPlans?.[0]?.id ? true : false;
  let toBePaid = useRates
    ? (absoluteTotal / paymentPlans?.[0]?.numberOfPayments)?.toFixed(2)
    : claim?.totalPending?.toFixed(2);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={3}>
          <Typography variant="h6" style={{ marginTop: 20, marginBottom: 10 }}>
            <Event style={{ verticalAlign: "sub" }} /> Payment Plan
          </Typography>
          <Button
            variant={claim?.paymentPlans?.length !== 0 ? "contained" : "text"}
            size={"medium"}
            disabled={claim?.paymentPlans?.length !== 0}
            href={
              "/#/PaymentPlan/create?claimId=" +
              claim?.id +
              "&debtorId=" +
              claim?.debtor?.id
            }
            startIcon={<Add />}
            label="Payment Plan"
          />
          {claim?.paymentPlans?.length !== 0 && (
            <>
              <br />
              <br />
              <Typography variant="subtitle2" style={{}}>
                Existing payment plan:
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={9}>
          <div>
            <Typography variant="h6" style={{ marginTop: 20 }}>
              <PaymentIcon style={{ verticalAlign: "sub" }} /> Payment Actions
            </Typography>
            <TopToolbar className="align-btns">
              <Button
                href={
                  "/#/Payment/create?claimId=" +
                  claim?.id +
                  "&amount=" +
                  (-toBePaid || 0) +
                  "&paymentType=" +
                  (useRates ? "PaymentRate" : "DebtClearance")
                }
                startIcon={<Add />}
                style={{ backgroundColor: theme.palette.success.dark }}
                label="Incoming"
              />
              <Button
                href={
                  "/#/Payment/create?claimId=" +
                  claim?.id +
                  "&amount=" +
                  (-claim?.originalAmountDue || 0) +
                  "&paymentType=WriteOffDiscount"
                }
                startIcon={<Add />}
                style={{ backgroundColor: theme.palette.error.dark }}
                label="Write-off"
              />
              <Button
                href={
                  "/#/Payment/create?claimId=" +
                  claim?.id +
                  "&amount=" +
                  (-currentCreditorAmountDueInclInterest || 0) +
                  "&paymentType=ExistingPayments"
                }
                startIcon={<Add />}
                style={{ backgroundColor: theme.palette.warning.dark }}
                label="Creditor Payment"
              />
              <Button
                href={"/#/Payment/create?claimId=" + claim?.id}
                startIcon={<Add />}
                label="Other"
              />
            </TopToolbar>
          </div>
        </Grid>
      </Grid>
      <ReferenceManyField reference="PaymentPlan" target="ClaimId">
        <Datagrid rowClick="show" optimized>
          <DateField
            source="createdAt"
            label="Created At"
            locales="de-DE"
            options={{
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
              timeZone: "Europe/Berlin",
            }}
          />
          <BooleanField label="Active" source="isActive" />
          <TextField label="Frequency" source="frequency" />
          <TextField label="Number Of Payments" source="numberOfPayments" />
          <FunctionField
            label="Next Payment Due"
            source="nextPaymentDueDate"
            locales="de-DE"
            render={(paymentPlan: any) => {
              if (claim?.totalPending > 0 && paymentPlan?.nextPaymentDueDate) {
                return (
                  <span>
                    {new Date(
                      paymentPlan.nextPaymentDueDate
                    )?.toLocaleDateString("de-DE", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })}
                  </span>
                );
              }
              return "-";
            }}
            options={{
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
              timeZone: "Europe/Berlin",
            }}
          />
          <BooleanField
            label="Has Court Title Note"
            source="hasCourtTitleNote"
          />
          <DateField
            source="updatedAt"
            label="Updated At"
            locales="de-DE"
            options={{
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              timeZone: "Europe/Berlin",
            }}
          />
        </Datagrid>
      </ReferenceManyField>
      <Typography variant="h6" style={{ marginTop: 20, marginBottom: 10 }}>
        <PaymentIcon style={{ verticalAlign: "sub" }} /> Payments
      </Typography>
      <ReferenceManyField
        addLabel
        reference="Payment"
        target="ClaimId"
        label="Payments"
        sort={{ field: "paymentDate", order: "DESC" }}
        pagination={<Pagination />}
        perPage={500}
      >
        <PaymentListItems claim={claim} />
      </ReferenceManyField>
    </>
  );
};

const ActivityInlineCreate = React.memo(
  (props: {
    activityPlaceholderText?: string;
    setActivityPlaceholderText?: any;
  }) => {
    const record: Claim = useRecordContext();

    return (
      <div>
        <Typography id="new-activity" variant="caption">
          New Activity
        </Typography>
        <ActivityCreate
          basePath="/Activity"
          resource="Activity"
          options={{
            inline: true,
            claimId: record?.id,
            placeholderComment: props.activityPlaceholderText,
            setPlaceholderComment: props.setActivityPlaceholderText,
          }}
        />
      </div>
    );
  }
);

export const getStatusToShow = (
  claim: Claim,
  activities: Activity[],
  paymentPlans: PaymentPlan[],
  payments: Payment[]
) => {
  let statusToShow = claim?.status || "";

  switch (statusToShow) {
    case "EncashmentLevel":
      if (paymentPlans?.length && paymentPlans[0].isActive) {
        statusToShow = "PaymentPlan";
      } else if (activities) {
        const firstActivityWithClaimAction = activities
          ?.slice()
          .find(
            (activity) =>
              !activity.isPlanned &&
              activity.claimAction &&
              (activity.claimAction.endsWith("Dunning") ||
                activity.claimAction.endsWith("Reminder")) &&
              activity.result !== "Negative"
          );

        if (
          firstActivityWithClaimAction &&
          firstActivityWithClaimAction.claimAction
        ) {
          statusToShow = firstActivityWithClaimAction.claimAction;
        }
      }
      break;
    case "Open":
    case "ReminderLevel":
    case "JudicialLevel":
      break;
    case "Closed":
    case "Paid":
      if (
        payments?.filter(
          (payment) => payment.paymentType === "Payout" && !payment.isPlanned
        ).length
      ) {
        statusToShow = "PaidOut";
      }
      break;
    default:
      break;
  }

  return getReadableSubjectByClaimAction(statusToShow);
};

export const TabbedShowLayoutStyled = withStyles({
  content: {
    overflow: "hidden",
    overflowX: "scroll",
  },
})(({ ...props }: any) => {
  return <TabbedShowLayout {...props} />;
});

const ClaimTabs = (props: any) => {
  const record: Claim = useRecordContext();
  const dataProvider = useDataProvider();
  const { identity } = useGetIdentity();
  const [showActivityAdd, setShowActivityAdd] = React.useState(false);
  const [activityPlaceholderText, setActivityPlaceholderText] =
    React.useState("");
  const [hasLawyers, setHasLawyers] = React.useState(false);
  const [userCountry, setUserCountry] = React.useState();
  const [hasInsolvencyAdministrators, setHasInsolvencyAdministrators] =
    React.useState(false);
  const [paymentPlans, setPaymentPlans] = React.useState<any>([]);
  const { data: creditorData } = useGetOne("User", record?.creditor.id || 0);
  const { data: debtorData } = useGetOne("User", record?.debtor.id || 0);
  const [emailTitle, setEmailTitle] = React.useState<string>("");
  const phoneRef = useRef(null);
  const [showAirCallPhone, setShowAirCallPhone] = useState(false);

  const { data: paymentData } = useGetList<Payment>(
    "Payment",
    { page: 1, perPage: 25 },
    { field: "paymentDate", order: "DESC" },
    { claim: { id: record?.id || 0 } }
  );
  const { data: activityData } = useGetList<Activity>(
    "Activity",
    { page: 1, perPage: 25 },
    { field: "activityDate", order: "DESC" },
    { claim: { id: record?.id || 0 } }
  );

  const notify = useNotify();

  useTitle(
    record?.reference +
      " " +
      (creditorData
        ? " " + creditorData?.businessName?.slice(0, 3).toUpperCase() + " "
        : "") +
      " ./. " +
      (debtorData?.businessName ||
        debtorData?.contactName ||
        debtorData?.name ||
        "")
  );

  useEffect(() => {
    const fetchData = async () => {
      if (record?.paymentPlans) {
        dataProvider
          .getMany("PaymentPlan", {
            ids: record?.paymentPlans?.map((paymentPlan) => paymentPlan.id),
          })
          .then(({ data }) => {
            setPaymentPlans(data);
          });
      }
    };

    fetchData();

    return () => {
      setHasLawyers(false);
      setHasInsolvencyAdministrators(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record, emailTitle]);

  const statusToShow = getStatusToShow(
    record,
    Object.values(activityData) as Activity[],
    paymentPlans,
    Object.values(paymentData) as Payment[]
  );
  const refresh = useRefresh();

  const handleCallDebtor = () => {
    setShowAirCallPhone(true);

    let debtorPhone = debtorData?.phone;

    if (!debtorPhone) {
      notify("No phone number available for this debtor", "warning");
      return;
    }

    debtorPhone = normalizePhoneNumber(debtorData?.phone, userCountry);
    setShowAirCallPhone(true);

    phoneRef.current?.send(
      "dial_number",
      { phone_number: normalizePhoneNumber(debtorPhone) },
      (success, data) => {
        if (success) {
          notify("Dialing Call", "success");
        } else {
          notify("Could not dial", "warning");
        }
      }
    );
  };

  return (
    <div>
      <div style={{ display: "none" }}>
        <AircallPhoneComponent
          killPadding
          hideStats
          showAirCallPhone={showAirCallPhone}
          setShowAirCallPhone={setShowAirCallPhone}
          phoneRef={phoneRef}
        />
      </div>

      <Title title={record?.reference + " - " + record?.title + " "} />
      <ClaimHeaderBar
        record={record}
        notify={notify}
        hasLawyers={hasLawyers}
        hasInsolvencyAdministrators={hasInsolvencyAdministrators}
        statusToShow={statusToShow}
        dataProvider={dataProvider}
        refresh={refresh}
      />
      <TabbedShowLayoutStyled>
        <Tab label="General" icon={<AppsOutlined />}>
          <Grid
            container
            spacing={2}
            style={{
              width: "100%",
              paddingTop: 15,
              marginBottom: 30,
              position: "relative",
            }}
          >
            <Grid item xs={12} lg={6}>
              <Typography variant={"h6"}>{"Claim:"}</Typography>
              <MuiList>
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={<Typography variant="caption">Title:</Typography>}
                    secondary={
                      <TextField
                        label="Title (External Identifier)"
                        source="title"
                        style={{ fontWeight: 600 }}
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={<Typography variant="caption">Status:</Typography>}
                    secondary={
                      <FunctionField
                        label="Status"
                        source="status"
                        style={{ fontWeight: 600 }}
                        render={(record: any) => {
                          const status = record?.status;
                          return (
                            <span
                              style={{
                                fontWeight: 600,
                                background: ["Paid", "PaidOut"].includes(status)
                                  ? theme.palette.success.dark
                                  : status === "Paused"
                                  ? theme.palette.grey[800]
                                  : status === "Open"
                                  ? theme.palette.primary.light
                                  : status === "JudicialLevel"
                                  ? theme.palette.error.dark
                                  : status === "EncashmentLevel"
                                  ? theme.palette.warning.main
                                  : status === "ReminderLevel"
                                  ? theme.palette.info.main
                                  : ["Closed", "Cancelled"].includes(status)
                                  ? theme.palette.error.main
                                  : theme.palette.grey[800],
                                color: "white",
                                padding: 7,
                                fontSize: 12,
                                borderRadius: 8,
                                paddingLeft: 10,
                                paddingRight: 10,
                              }}
                            >
                              {record?.status}
                            </span>
                          );
                        }}
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={<Typography variant="caption">Stage:</Typography>}
                    secondary={
                      <FunctionField
                        label="Stage"
                        source="stage"
                        style={{ fontWeight: 600 }}
                        render={(record: any) => {
                          const stage = record?.stage;
                          return (
                            <span
                              style={{
                                fontWeight: 600,
                                color: !stage
                                  ? theme.palette.grey[800]
                                  : stage === "Reminder"
                                  ? theme.palette.info.main
                                  : stage === "Precourt"
                                  ? theme.palette.warning.main
                                  : stage === "Court"
                                  ? theme.palette.error.dark
                                  : stage === "Monitoring"
                                  ? theme.palette.info.dark
                                  : theme.palette.grey[800],
                                fontSize: 12,
                                borderRadius: 8,
                                paddingRight: 10,
                              }}
                            >
                              {stage === "Precourt" ? (
                                <Mail
                                  style={{
                                    height: 14,
                                    position: "relative",
                                    top: 3,
                                  }}
                                />
                              ) : stage === "Court" ? (
                                <Gavel
                                  style={{
                                    height: 14,
                                    position: "relative",
                                    top: 3,
                                  }}
                                />
                              ) : stage === "Monitoring" ? (
                                <Visibility
                                  style={{
                                    height: 14,
                                    position: "relative",
                                    top: 3,
                                  }}
                                />
                              ) : stage === "Reminder" ? (
                                <Notifications
                                  style={{
                                    height: 14,
                                    position: "relative",
                                    top: 3,
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                              {stage}
                            </span>
                          );
                        }}
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">
                        Creditor Feedback Required:
                      </Typography>
                    }
                    secondary={
                      <BooleanField
                        label="Creditor Feedback Required"
                        source="creditorFeedbackRequired"
                        emptyText="x"
                        color={"secondary"}
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Created At:</Typography>
                    }
                    secondary={
                      <DateField
                        source="createdAt"
                        label="Created At"
                        locales="de-DE"
                        options={{
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          timeZone: "Europe/Berlin",
                        }}
                        style={{ fontWeight: 600 }}
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Updated At:</Typography>
                    }
                    secondary={
                      <>
                        <ReferenceField
                          label="Manager"
                          source="manager.id"
                          reference="User"
                          link="show"
                        >
                          <FunctionField
                            label="Manager"
                            render={(record: any) => (
                              <span>
                                {record?.username?.split("@")?.[0]}
                                <Typography
                                  variant="caption"
                                  color="textPrimary"
                                >
                                  :
                                </Typography>
                                &nbsp;
                              </span>
                            )}
                          />
                        </ReferenceField>
                        <DateField
                          source="updatedAt"
                          label="Updated At"
                          locales="de-DE"
                          options={{
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            timeZone: "Europe/Berlin",
                          }}
                          style={{ fontWeight: 600 }}
                        />
                      </>
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">External ID:</Typography>
                    }
                    secondary={
                      <TextField
                        label="External ID"
                        source="externalId"
                        emptyText="-"
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Claim type:</Typography>
                    }
                    secondary={
                      <TextField
                        label="Claim Type"
                        source="claimType"
                        emptyText="-"
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Interest Rate:</Typography>
                    }
                    secondary={
                      <TextField
                        label="Interest Rate"
                        source="interestRate"
                        emptyText="-"
                      />
                    }
                  ></ListItemText>
                </ListItem>
              </MuiList>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Typography variant={"h6"}>{"Debtor:"}</Typography>
              <MuiList>
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={<Typography variant="caption">ID:</Typography>}
                    secondary={
                      <TextField
                        label="ID "
                        source="debtor.id"
                        style={{ fontWeight: 600 }}
                      />
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Business Name:</Typography>
                    }
                    secondary={
                      <ReferenceField
                        link=""
                        label="Debtor"
                        source="debtor.id"
                        reference="User"
                      >
                        <FunctionField
                          label="Debtor"
                          render={(record: any) => <UserBusinessName />}
                        />
                      </ReferenceField>
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Business Type:</Typography>
                    }
                    secondary={
                      <ReferenceField
                        link=""
                        label="Debtor"
                        source="debtor.id"
                        reference="User"
                      >
                        <FunctionField
                          label="Debtor"
                          render={(record: any) => (
                            <span>
                              <span
                                title="Business Type"
                                style={{
                                  color:
                                    record?.businessType === "Consumer"
                                      ? "#995200"
                                      : "#000099",
                                  background:
                                    record?.businessType === "Consumer"
                                      ? "#ffc480"
                                      : "#d1d1ff",
                                  padding: "5px 10px",
                                  marginRight: 8,
                                  fontSize: 12,
                                  fontWeight: "bold",
                                  borderRadius: 8,
                                }}
                              >
                                {record?.businessType === "Consumer"
                                  ? "B2C"
                                  : "B2B"}
                              </span>
                            </span>
                          )}
                        />
                      </ReferenceField>
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">Contact Name:</Typography>
                    }
                    secondary={
                      <ReferenceField
                        link=""
                        label="Debtor"
                        source="debtor.id"
                        reference="User"
                      >
                        <FunctionField
                          label="Debtor"
                          render={(record: any) => {
                            return <span>{record?.contactName || "-"}</span>;
                          }}
                        />
                      </ReferenceField>
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">{`${emailTitle}`}</Typography>
                    }
                    secondary={
                      <ReferenceField
                        link=""
                        label="Debtor"
                        source="debtor.id"
                        reference="User"
                      >
                        <FunctionField
                          label="Debtor"
                          render={(record: any) => {
                            setEmailTitle(record?.email ? "Email" : "Username");

                            if (record?.email) {
                              return <UserEmail />;
                            }

                            return <span>{record?.username}</span>;
                          }}
                        />
                      </ReferenceField>
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />

                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    primary={
                      <Typography variant="caption">{"Phone:"}</Typography>
                    }
                    secondary={
                      <ReferenceField
                        link={false}
                        label="Debtor"
                        source="debtor.id"
                        reference="User"
                      >
                        <PhoneNumberComponent
                          setShowAirCallPhone={setShowAirCallPhone}
                          phoneRef={phoneRef}
                        />
                      </ReferenceField>
                    }
                  ></ListItemText>
                </ListItem>
                <Divider component="li" />
                <ListItem alignItems={"flex-start"}>
                  <ListItemText
                    secondary={
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption" color="textPrimary">
                            Address:
                          </Typography>
                        }
                        secondary={
                          <ListItemText
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                            primary={
                              <ReferenceField
                                link=""
                                label="Debtor"
                                source="debtor.id"
                                reference="User"
                              >
                                <ReferenceField
                                  link="show"
                                  label="Address"
                                  source="address.id"
                                  reference="Address"
                                >
                                  <>
                                    <TextField source={"addressLine1"} />
                                    ,&nbsp;
                                    <TextField source={"postalcode"} />
                                    &nbsp;
                                    <TextField source={"town"} />
                                    ,&nbsp;
                                    <FunctionField
                                      label=""
                                      render={(record: any) => {
                                        if (record?.country) {
                                          setUserCountry(record.country);
                                        }

                                        return (
                                          <span>
                                            {record?.country
                                              ? ADDRESS_COUNTRY_READABLE.get(
                                                  record.country
                                                )
                                              : ""}
                                          </span>
                                        );
                                      }}
                                    />
                                    <FunctionField
                                      label=""
                                      render={(record: any) => {
                                        if (!record.country) {
                                          return null;
                                        }

                                        const iso2CoutryCode = searchMapByKey(
                                          ADDRESS_COUNTRY_SHORT,
                                          record.country as EnumAddressCountry
                                        );
                                        if (!iso2CoutryCode) {
                                          return null;
                                        }

                                        return (
                                          <ReactCountryFlag
                                            countryCode={iso2CoutryCode}
                                            svg
                                            style={{ marginLeft: 4 }}
                                          />
                                        );
                                      }}
                                    />
                                    <FunctionField
                                      label="Verification Status"
                                      render={(record: any) => (
                                        <span>
                                          {(!record.verificationStatus ||
                                            record.verificationStatus ===
                                              "UNKNOWN") && (
                                            <Help
                                              style={{
                                                height: 15,
                                                color:
                                                  theme.palette.warning.dark,
                                              }}
                                            />
                                          )}
                                          {record.verificationStatus ===
                                            "VALID" && (
                                            <VerifiedUser
                                              style={{
                                                height: 15,
                                                color:
                                                  theme.palette.success.main,
                                              }}
                                            />
                                          )}
                                          {record.verificationStatus ===
                                            "INVALID" && (
                                            <RemoveCircle
                                              style={{
                                                height: 15,
                                                color: theme.palette.error.main,
                                              }}
                                            />
                                          )}
                                        </span>
                                      )}
                                    />
                                  </>
                                </ReferenceField>
                              </ReferenceField>
                            }
                          ></ListItemText>
                        }
                      ></ListItemText>
                    }
                  ></ListItemText>
                </ListItem>
              </MuiList>
            </Grid>
          </Grid>

          <Contacts
            hasLawyers={hasLawyers}
            setHasLawyers={setHasLawyers}
            hasInsolvencyAdministrators={hasInsolvencyAdministrators}
            setHasInsolvencyAdministrators={setHasInsolvencyAdministrators}
            userCountry={userCountry}
            phoneRef={phoneRef}
            setShowAirCallPhone={setShowAirCallPhone}
          />
        </Tab>

        <Tab label="Payments" icon={<PaymentOutlined />}>
          <Grid container spacing={2} style={{ padding: "30px 0" }}>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2}
              style={{ width: "20%", minWidth: "20%" }}
            >
              <Card>
                <CardContent className="content-flexCol">
                  <Typography variant="body2">Original amount:</Typography>
                  <NumberField
                    label="Original Amount Due (gross)"
                    source="originalAmountDue"
                    locales={"de-DE"}
                    options={{
                      style: "currency",
                      currency: record?.currency || "EUR",
                      minimumFractionDigits: 2,
                    }}
                    style={{ fontWeight: 600 }}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2}
              style={{ width: "20%", minWidth: "20%" }}
            >
              <Card>
                <CardContent className="content-flexCol">
                  <Typography variant="body2">Total Pending:</Typography>
                  <NumberField
                    label="Total Pending"
                    source="totalPending"
                    locales={"de-DE"}
                    options={{
                      style: "currency",
                      currency: record?.currency || "EUR",
                      minimumFractionDigits: 2,
                    }}
                    style={{ fontWeight: 600 }}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2}
              style={{ width: "20%", minWidth: "20%" }}
            >
              <Card>
                <CardContent className="content-flexCol">
                  <Typography variant="body2">Total Paid:</Typography>
                  <NumberField
                    label="Total Paid"
                    source="totalPaid"
                    defaultValue={record?.totalPaid ?? 0}
                    locales={"de-DE"}
                    style={{
                      fontWeight: 600,
                      color:
                        record?.totalPaid === 0
                          ? theme.palette.error.light
                          : theme.palette.success.dark,
                    }}
                    options={{
                      style: "currency",
                      currency: "EUR",
                      minimumFractionDigits: 2,
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2}
              style={{ width: "20%", minWidth: "20%" }}
            >
              <Card>
                <CardContent className="content-flexCol">
                  <Typography variant="body2">Invoice Created:</Typography>
                  <DateField
                    label="Invoice Created At"
                    source="invoiceCreatedAt"
                    locales="de-DE"
                    options={{
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                      timeZone: "Europe/Berlin",
                    }}
                    style={{ fontWeight: 600 }}
                  />
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2}
              style={{ width: "20%", minWidth: "20%" }}
            >
              <Card>
                <CardContent className="content-flexCol">
                  <Typography variant="body2">Due Date:</Typography>
                  <DateField
                    label="Due Date"
                    source="dueDate"
                    locales="de-DE"
                    options={{
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                      timeZone: "Europe/Berlin",
                    }}
                    color="error"
                    style={{ fontWeight: 600 }}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <PaymentList
            toBePaidOut={props.toBePaidOut}
            absoluteTotal={props.absoluteTotal}
            paymentPlans={paymentPlans}
            currentCreditorAmountDueInclInterest={
              props.currentCreditorAmountDueInclInterest
            }
          />
        </Tab>

        <Tab label="Activities" icon={<ChatOutlined />}>
          <ClaimActivityTools
            showActivityAdd={showActivityAdd}
            creditor={creditorData}
            debtor={debtorData}
            onCreateButtonClick={(placeholderText?: string) => {
              setActivityPlaceholderText(placeholderText);
              setShowActivityAdd(!showActivityAdd);
            }}
          />
          {showActivityAdd && (
            <ActivityInlineCreate
              activityPlaceholderText={activityPlaceholderText}
              setActivityPlaceholderText={setActivityPlaceholderText}
            />
          )}
          <ReferenceManyField
            reference="Activity"
            target="ClaimId"
            label="Activities"
            fullWidth
            perPage={100}
            pagination={<Pagination />}
            sort={{ field: "activityDate", order: "DESC" }}
          >
            <ActivityListItems />
          </ReferenceManyField>
          <br />
        </Tab>

        {(identity?.username?.includes("debtist.de") ||
          identity?.username === "admin") && (
          <Tab label="Files" icon={<FileCopyOutlined />}>
            <FileList />
          </Tab>
        )}

        <Tab
          label="Comment"
          icon={
            <Badge
              invisible={!record?.comment?.length}
              badgeContent={<></>}
              color={"primary"}
              variant="dot"
              style={{ marginTop: 4 }}
            >
              <CommentOutlined />
            </Badge>
          }
          style={{
            background: record?.comment?.length ? "var(--primary)" : "",
            color: record?.comment?.length ? "#fff" : "",
          }}
        >
          {record?.comment?.length && (
            <div
              style={{
                marginTop: 20,
                background: theme.palette.grey[100],
                padding: 20,
                borderRadius: 8,
              }}
            >
              <RichTextField
                label="Comment"
                source="comment"
                style={{
                  whiteSpace: "pre-wrap",
                }}
              />
            </div>
          )}
        </Tab>
        <Button
          hidden={!record.debtor?.phone}
          title="Call Debtor"
          onClick={handleCallDebtor}
          style={{
            position: "absolute",
            right: 25,
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          <CallOutlined />
        </Button>
      </TabbedShowLayoutStyled>
      {VIP_USERS.includes(identity?.username) && <ClaimDrawer />}
      <div
        className={`aircallPhone ${showAirCallPhone ? "-open" : ""}`}
        id="phone"
      ></div>
    </div>
  );
};

const Contacts = ({
  hasLawyers,
  setHasLawyers,
  hasInsolvencyAdministrators,
  setHasInsolvencyAdministrators,
  userCountry,
  phoneRef,
  setShowAirCallPhone,
}) => {
  const claim = useRecordContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  if (!claim) {
    return null;
  }

  return (
    <>
      <Typography variant="h6">
        <PersonAddOutlined style={{ verticalAlign: "sub" }} /> Contacts
      </Typography>
      <TopToolbar className="align-btns">
        <Button
          href={
            "/#/User/create?claimId=" +
            claim?.id +
            "&userType=AdditionalContact"
          }
          startIcon={<Add />}
          style={{ backgroundColor: theme.palette.success.dark }}
          label="Contact"
        />
        <Button
          href={"/#/User/create?claimId=" + claim?.id + "&userType=Lawyer"}
          startIcon={<Add />}
          style={{ backgroundColor: theme.palette.error.dark }}
          label="Lawyer"
        />
        <Button
          href={
            "/#/User/create?claimId=" +
            claim?.id +
            "&userType=InsolvencyAdministrator"
          }
          startIcon={<Add />}
          style={{ backgroundColor: theme.palette.warning.dark }}
          label="Insolvency manager"
        />
      </TopToolbar>
      {claim?.id && (
        <List
          resource="User"
          basePath="/User"
          exporter={false}
          title={" "}
          actions={false}
          filter={{
            claimsAsContact: { some: { id: { equals: claim?.id } } },
          }}
          filterDefaultValues={{
            claimsAsContact: { some: { id: { equals: claim?.id } } },
          }}
          perPage={50}
          pagination={null}
          sort={{ field: "userType", order: "DESC" }}
        >
          <Datagrid
            optimized
            size="medium"
            isRowSelectable={() => false}
            hasBulkActions={false}
            rowClick=""
          >
            <ReferenceField
              link={false}
              label="Contacts"
              source="id"
              reference="User"
              emptyText="-"
              sortBy="userType"
            >
              <FunctionField
                label="Contact"
                render={(record: any) => {
                  if (
                    record?.userType === "Lawyer" &&
                    !hasLawyers &&
                    claim?.contacts &&
                    claim.contacts.some((contact) => contact?.id === record?.id)
                  ) {
                    setHasLawyers(true);
                  }
                  if (
                    record?.userType === "InsolvencyAdministrator" &&
                    !hasInsolvencyAdministrators &&
                    claim?.contacts &&
                    claim.contacts.some((contact) => contact?.id === record?.id)
                  ) {
                    setHasInsolvencyAdministrators(true);
                  }

                  return (
                    <Grid container>
                      <Grid xs={2} item>
                        <a
                          style={{
                            textDecoration: "none",
                            fontWeight: 600,
                            color: theme.palette.grey[700],
                          }}
                          href={`/#/User/${record?.id}/show`}
                        >
                          {record?.userType &&
                            record?.userType !== "Default" && (
                              <span
                                style={{
                                  color:
                                    record?.userType !== "AdditionalContact"
                                      ? theme.palette.error.main
                                      : theme.palette.warning.dark,
                                }}
                              >
                                {getReadableUserType(record?.userType)}
                                {record?.userType === "AdditionalContact" && (
                                  <span
                                    title="Kontakt"
                                    style={{
                                      background: theme.palette.warning.dark,
                                      color: "white",
                                      padding: "8px 10px",
                                      marginLeft: 5,
                                      fontSize: 12,
                                      fontWeight: "bold",
                                      borderRadius: 8,
                                    }}
                                  >
                                    <PersonAdd
                                      style={{
                                        height: 12,
                                        width: 12,
                                        position: "relative",
                                        top: 2,
                                      }}
                                    />
                                  </span>
                                )}
                                {record?.userType === "Lawyer" && (
                                  <span
                                    title="Anwalt"
                                    style={{
                                      background: theme.palette.error.dark,
                                      color: "white",
                                      padding: 5,
                                      marginLeft: 5,
                                      fontSize: 12,
                                      fontWeight: "bold",
                                      borderRadius: 8,
                                    }}
                                  >
                                    <Gavel
                                      style={{
                                        height: 12,
                                        width: 12,
                                        position: "relative",
                                        top: 2,
                                      }}
                                    />
                                  </span>
                                )}
                                {record?.userType ===
                                  "InsolvencyAdministrator" && (
                                  <span
                                    title="Insolvenzverwalter"
                                    style={{
                                      background: theme.palette.error.dark,
                                      color: "white",
                                      padding: 5,
                                      marginLeft: 3,
                                      fontSize: 12,
                                      fontWeight: "bold",
                                      borderRadius: 8,
                                    }}
                                  >
                                    <MoneyOff
                                      style={{
                                        height: 12,
                                        width: 12,
                                        position: "relative",
                                        top: 2,
                                      }}
                                    />
                                  </span>
                                )}
                              </span>
                            )}
                        </a>
                      </Grid>
                      <Grid xs={9} item>
                        <a
                          style={{
                            textDecoration: "none",
                            color: theme.palette.grey[700],
                          }}
                          href={`/#/User/${record?.id}/show`}
                        >
                          <span
                            style={{
                              color: theme.palette.primary.main,
                            }}
                          >
                            {record?.name}
                          </span>
                        </a>
                        {!!record?.name ? " | " : ""} <UserEmail />
                        {!!record?.email && !!record?.phone ? " | " : ""}{" "}
                        <PhoneNumberComponent
                          inline
                          phoneRef={phoneRef}
                          setShowAirCallPhone={setShowAirCallPhone}
                          country={userCountry}
                        />
                      </Grid>
                      <Grid xs={1} item style={{ position: "relative" }}>
                        <CloseRounded
                          style={{
                            height: 16,
                            cursor: "pointer",
                            color: theme.palette.error.main,
                            padding: 15,
                            verticalAlign: "middle",
                            position: "absolute",
                            right: 0,
                            top: -15,
                            zIndex: 3,
                          }}
                          onClick={async (e) => {
                            e.stopPropagation();
                            if (
                              window.confirm(
                                "Do you really want to remove this contact?"
                              )
                            ) {
                              const newContacts = [];
                              claim?.contacts
                                .filter((contact) => contact?.id !== record?.id)
                                .forEach((contact) => {
                                  newContacts.push({ id: contact.id });
                                });
                              // todo: make it work
                              await dataProvider.update("Claim", {
                                id: claim.id,
                                data: {
                                  contacts: newContacts.length
                                    ? {
                                        set: newContacts,
                                      }
                                    : null,
                                },
                                previousData: record,
                              });
                              refresh();
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  );
                }}
              />
            </ReferenceField>
          </Datagrid>
        </List>
      )}
    </>
  );
};
