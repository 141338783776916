import { TODAY } from "./DateUtils";

export interface ClaimFilterOptions {
  personalization: boolean;
  enableLegal: boolean;
  languageFilter: string;
  userTypeFilter: string;
  activeSearchTerm: string;
  claimsSortField: string;
  claimsSortOrder: "asc" | "desc";
}

export const fetchPlannedCalls = async (
  serverUrl: string,
  options: ClaimFilterOptions
) => {
  const {
    personalization,
    enableLegal,
    languageFilter,
    userTypeFilter,
    activeSearchTerm,
    claimsSortField,
    claimsSortOrder,
  } = options;

  const body = {
    type: "calls",
    disablePersonalization: personalization === false ? true : undefined,
    enableLegal: enableLegal === true ? true : undefined,
    where: {
      creditorFeedbackRequired: {
        not: true,
      },
      stage: {
        in: ["Reminder", "Precourt", "Court", "Monitoring"],
      },
      status: {
        not: "Open",
      },
      activities: {
        some: {
          activityDate: {
            lte: TODAY,
          },
          activityType: "OutboundCall",
          isPlanned: true,
        },
      },
      ...(languageFilter === "German" && {
        debtor: {
          address: {
            country: {
              in: ["DEU", "AUT", "CHE"],
            },
          },
          ...(userTypeFilter === "Consumer" && {
            businessType: "Consumer",
          }),
          ...(userTypeFilter === "Business" && {
            businessType: {
              not: "Consumer",
            },
          }),
        },
      }),
      ...(languageFilter === "Local" && {
        debtor: {
          address: {
            country: {
              in: [
                "ALB",
                "ARE",
                "ARM",
                "AZE",
                "BEL",
                "BIH",
                "BGR",
                "BHR",
                "BLR",
                "CYP",
                "CZE",
                "DNK",
                "ESP",
                "EST",
                "FIN",
                "FRA",
                "GBR",
                "GEO",
                "GRC",
                "HRV",
                "HUN",
                "IRL",
                "ISL",
                "ITA",
                "KAZ",
                "KGZ",
                "LTU",
                "LUX",
                "LVA",
                "MKD",
                "MLT",
                "MNE",
                "MNG",
                "NLD",
                "NOR",
                "POL",
                "PRT",
                "ROU",
                "RUS",
                "SAU",
                "SRB",
                "SVK",
                "SVN",
                "SWE",
                "TJK",
                "TKM",
                "UKR",
                "UZB",
                "BMU",
                "JEY",
                "DZA",
                "AGO",
                "BEN",
                "BFA",
                "BDI",
                "CPV",
                "CMR",
                "CAF",
                "COM",
                "COD",
                "DJI",
                "EGY",
                "GNQ",
                "ERI",
                "SWZ",
                "ETH",
                "GAB",
                "GMB",
                "GHA",
                "GIN",
                "CIV",
                "KEN",
                "LSO",
                "LBR",
                "MDG",
                "MWI",
                "MLI",
                "MAR",
                "MOZ",
                "NAM",
                "NER",
                "NGA",
                "RWA",
                "STP",
                "SEN",
                "SYC",
                "SLE",
                "SOM",
                "ZAF",
                "SSD",
                "SDN",
                "TZA",
                "TGO",
                "TUN",
                "UGA",
                "ZMB",
                "ZWE",
              ],
            },
          },
          ...(userTypeFilter === "Consumer" && {
            businessType: "Consumer",
          }),
          ...(userTypeFilter === "Business" && {
            businessType: {
              not: "Consumer",
            },
          }),
        },
      }),
      ...(languageFilter === "NonLocal" && {
        debtor: {
          address: {
            country: {
              notIn: [
                "ALB",
                "ARM",
                "AZE",
                "AUT",
                "BEL",
                "BIH",
                "BGR",
                "BLR",
                "CHE",
                "CYP",
                "CZE",
                "DEU",
                "DNK",
                "ESP",
                "EST",
                "FIN",
                "FRA",
                "GBR",
                "GEO",
                "GRC",
                "HRV",
                "HUN",
                "IRL",
                "ISL",
                "ITA",
                "KAZ",
                "KGZ",
                "LTU",
                "LUX",
                "LVA",
                "MKD",
                "MLT",
                "MNE",
                "NLD",
                "NOR",
                "POL",
                "PRT",
                "ROU",
                "RUS",
                "SRB",
                "SVK",
                "SVN",
                "SWE",
                "TJK",
                "TKM",
                "UKR",
                "UZB",
                "DZA",
                "AGO",
                "BEN",
                "BFA",
                "BDI",
                "CPV",
                "CMR",
                "CAF",
                "COM",
                "COD",
                "DJI",
                "EGY",
                "GNQ",
                "ERI",
                "SWZ",
                "ETH",
                "GAB",
                "GMB",
                "GHA",
                "GIN",
                "CIV",
                "KEN",
                "LSO",
                "LBR",
                "MDG",
                "MWI",
                "MLI",
                "MAR",
                "MOZ",
                "NAM",
                "NER",
                "NGA",
                "RWA",
                "STP",
                "SEN",
                "SYC",
                "SLE",
                "SOM",
                "ZAF",
                "SSD",
                "SDN",
                "TZA",
                "TGO",
                "TUN",
                "UGA",
                "ZMB",
                "ZWE",
              ],
            },
          },
          ...(userTypeFilter === "Consumer" && {
            businessType: "Consumer",
          }),
          ...(userTypeFilter === "Business" && {
            businessType: {
              not: "Consumer",
            },
          }),
        },
      }),
      ...(languageFilter === "NonGerman" && {
        debtor: {
          address: {
            country: {
              notIn: ["DEU", "AUT", "CHE"],
            },
          },
          ...(userTypeFilter === "Consumer" && {
            businessType: "Consumer",
          }),
          ...(userTypeFilter === "Business" && {
            businessType: {
              not: "Consumer",
            },
          }),
        },
      }),
      ...(userTypeFilter === "Consumer" && {
        debtor: {
          businessType: "Consumer",
        },
      }),
      ...(userTypeFilter === "Business" && {
        debtor: {
          businessType: {
            not: "Consumer",
          },
        },
      }),
      ...(activeSearchTerm && {
        creditor: {
          id: activeSearchTerm,
        },
      }),
    },
    orderBy: {
      [claimsSortField]: claimsSortOrder,
    },
  };

  const authHeader = {
    headers: {
      Authorization: localStorage.getItem("credentials") || "",
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(`${serverUrl}/api/claims/internal`, {
      method: "POST",
      body: JSON.stringify(body),
      ...authHeader,
    });

    console.log("response -------->", response);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching planned calls:", error);
    throw error;
  }
};
