import {
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Input,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { AccountBalance, CreditCard } from "@material-ui/icons";
import EmojiEvents from "@material-ui/icons/EmojiEvents";
import currency from "currency.js";
import { format } from "date-fns";
import jsonExport from "jsonexport/dist";
import { useEffect, useState } from "react";
import {
  AutocompleteInput,
  Button,
  LinearProgress,
  SelectInput,
  SimpleForm,
  Title,
  downloadCSV,
  useDataProvider,
  useGetList,
  useNotify,
} from "react-admin";
import { utils, writeFile } from "xlsx";
import { ADDRESS_COUNTRY_SHORT } from "../api/address/Address";
import { EnumAddressCountry } from "../api/address/EnumAddressCountry";
import { Claim } from "../api/claim/Claim";
import { Payment } from "../api/payment/Payment";
import { User } from "../api/user/User";
import AppConfig from "../config/AppConfig";
import { theme } from "../theme/theme";
import { startsWithAnyTwoLetters } from "../util/ClaimUtils";
import { getDaysInMonth } from "../util/DateUtils";
import { searchMapByKey } from "../util/MapUtils";

const INCOME_GOAL = 3200000; // https://www.flyoke.com/listings/airbus-h125-for-sale/
const INCOME_GOAL_TITLE = "Airbus H125 Helicopter";

const today = new Date();
today.setHours(23);
today.setMinutes(59);

const PaymentOverview = () => {
  const actualCurrentDate: Date = new Date();
  const actualCurrentYear: number = actualCurrentDate.getFullYear();
  const [currentYear, setCurrentYear] = useState<number>(actualCurrentYear);
  const [currentMonth, setCurrentMonth] = useState<number>(
    actualCurrentDate.getMonth()
  );

  const [currentDate, setCurrentDate] = useState<number>(null);

  const availableMonths = [
    { label: "(none)", value: null },
    {
      label: "January",
      value: 0,
    },
    {
      label: "February",
      value: 1,
    },
    {
      label: "March",
      value: 2,
    },
    {
      label: "April",
      value: 3,
    },
    {
      label: "May",
      value: 4,
    },
    {
      label: "June",
      value: 5,
    },
    {
      label: "July",
      value: 6,
    },
    {
      label: "August",
      value: 7,
    },
    {
      label: "September",
      value: 8,
    },
    {
      label: "October",
      value: 9,
    },
    {
      label: "November",
      value: 10,
    },
    {
      label: "December",
      value: 11,
    },
  ];

  return (
    <>
      <style>
        {`
        // whoever did this... needs to find Jesus.
  .shineEffect figure {
  margin: 0;
  position: relative;
  }
  .shineEffect figure::before {
  position: absolute;
  top: 0;
  left: -35%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
  background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
  animation-iteration-count: 1;
    animation: shine 2.75s;
  }
  .shineEffect figure::before {
  -webkit-animation: shine 2.75s;
  animation: shine 2.75s;
  animation-iteration-count: infinite;
  }
  @-webkit-keyframes shine {
  100% {
  left: 125%;
  }
  }
  @keyframes shine {
  100% {
  left: 125%;
  }
  }
`}
      </style>
      <Title title="Payment Overview" />
      <Typography variant="h4" style={{ marginLeft: 10 }}>
        Payment Overview
      </Typography>
      <SimpleForm toolbar={false} onSubmit={() => undefined}>
        <Grid container spacing={2} style={{ minWidth: "100%", width: "100%" }}>
          <Grid item xs={12} lg={4}>
            <SelectInput
              source="paymentDateDate"
              alwaysOn
              label="Day"
              value={currentDate}
              defaultValue={currentDate}
              onChange={(e) => {
                setCurrentDate(e.target.value as unknown as number);
              }}
              choices={[
                { label: "(none)", value: 0 },
                ...getDaysInMonth(currentMonth),
              ]}
              optionText="label"
              optionValue="value"
              style={{
                marginLeft: -6,
                marginRight: 10,
              }}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <SelectInput
              source="paymentDateMonths"
              alwaysOn
              label="Month"
              value={currentMonth}
              onChange={(e) => {
                setCurrentMonth(e.target.value as unknown as number);
              }}
              choices={availableMonths}
              optionText="label"
              optionValue="value"
              style={{
                marginRight: 10,
              }}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <SelectInput
              source="paymentDateYear"
              alwaysOn
              label="Year"
              value={currentYear}
              onChange={(e) =>
                setCurrentYear(e.target.value as unknown as number)
              }
              choices={[
                { label: "(none)", value: null },
                {
                  label: actualCurrentYear.toString(),
                  value: actualCurrentYear,
                },
                {
                  label: (actualCurrentYear - 1).toString(),
                  value: new Date(actualCurrentYear - 1, 1, 1).getFullYear(),
                },
                {
                  label: (actualCurrentYear - 2).toString(),
                  value: new Date(actualCurrentYear - 2, 1, 1).getFullYear(),
                },
                {
                  label: (actualCurrentYear - 3).toString(),
                  value: new Date(actualCurrentYear - 3, 1, 1).getFullYear(),
                },
                {
                  label: (actualCurrentYear - 4).toString(),
                  value: new Date(actualCurrentYear - 4, 1, 1).getFullYear(),
                },
              ]}
              optionText="label"
              optionValue="value"
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          direction="row-reverse"
          style={{ minWidth: "100%", width: "100%" }}
        >
          <Grid item xs={12} md={6}>
            <PayoutList
              currentMonth={currentMonth}
              currentYear={currentYear}
              currentDate={currentDate}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <UnpaidList
              currentMonth={currentMonth}
              currentYear={currentYear}
              currentDate={currentDate}
            />
            <PaymentUpload />
          </Grid>
        </Grid>
        <ClaimFileUpload />
      </SimpleForm>
    </>
  );
};

const ClaimFileUpload = () => {
  const dataProvider = useDataProvider();

  const uploadProps = {
    name: "file",
    onChange: async (event: React.ChangeEvent<HTMLInputElement>) => {
      const authHeader = {
        headers: { Authorization: localStorage.getItem("credentials") || "" },
      };

      const files = event.target.files;

      if (files && files.length > 0) {
        try {
          for (const file of Array.from(files)) {
            const formData = new FormData();
            formData.append("file", file);

            const claimTitle = file.name.split(".")[0];
            if (claimTitle) {
              const { data: claimData } = await dataProvider.getList("Claim", {
                pagination: { page: 1, perPage: 3 },
                sort: { field: "createdAt", order: "DESC" },
                filter: {
                  title: { contains: claimTitle },
                  creditor: { id: "clrj1fgdo0002d26zangxf4dy" },
                },
              });

              await fetch(
                process.env.REACT_APP_SERVER_URL +
                  "/api/claims/" +
                  (claimData[0] as Claim).id +
                  "/files",
                {
                  method: "POST",
                  body: formData,
                  ...authHeader,
                }
              );

              // Optional: You can add a delay between each request
              await new Promise((resolve) => setTimeout(resolve, 250));
            }
          }
        } catch (error) {}
      }
    },
  };

  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <br />
      Claim files:
      <br />
      <Input
        type="file"
        {...uploadProps}
        inputProps={{ multiple: true }}
        style={{ marginTop: "1rem" }}
      />
    </>
  );
};

// Helper component to render table headers
function TableHeader({ creditors }: { creditors: string[] }) {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Date</TableCell>
        {creditors.map((creditor) => (
          <TableCell key={creditor} align="right">
            {creditor}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// Helper component to render table cells for a specific row
function TableRowComponent({
  date,
  creditors,
  totals,
  dailyTotal,
}: {
  date: string;
  creditors: string[];
  totals: CreditorProfits;
  dailyTotal: number;
}) {
  return (
    <TableRow key={date}>
      <TableCell>
        {date}{" "}
        <strong>
          (
          {Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR",
          }).format(dailyTotal)}
          )
        </strong>
      </TableCell>
      {creditors.map((creditor) => (
        <TableCell key={creditor} align="right">
          {totals[creditor]
            ? Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
              }).format(totals[creditor])
            : "-"}
        </TableCell>
      ))}
    </TableRow>
  );
}

// Main component to render the entire table with MUI
function GroupedClaimsTable({
  claims,
  currentMonth,
  currentYear,
}: {
  claims: any[];
  currentMonth?: number;
  currentYear?: number;
}) {
  const groupedClaims = groupClaimsByDayAndCreditor(
    claims,
    currentMonth,
    currentYear
  );

  // Extract all unique creditors
  const allCreditors = Array.from(
    new Set(groupedClaims.flatMap(([_, data]) => Object.keys(data.creditors)))
  );

  const exportXls = () => {
    try {
      // Format function to handle the date
      const formatDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-based
        const day = String(d.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      // Map the data and format the date + extract creditors properties
      const data = groupedClaims.map(([date, obj]) => {
        const creditors = obj.creditors || {};

        // Create a new row with the formatted date and spread the creditors properties
        return {
          Date: formatDate(date), // Format date as YYYY-MM-DD
          ...creditors,
        };
      });

      // Convert the array of objects to a worksheet
      const worksheet = utils.json_to_sheet(data);

      // Specify date formatting for the "Date" column (first column)
      const range = utils.decode_range(worksheet["!ref"]);
      for (let row = range.s.r + 1; row <= range.e.r; row++) {
        const cellRef = utils.encode_cell({ r: row, c: 0 }); // Date column is the first (c: 0)
        if (worksheet[cellRef]) {
          worksheet[cellRef].t = "d"; // Mark as date type
          worksheet[cellRef].z = "yyyy-mm-dd"; // Apply Excel's date formatting
        }
      }

      const workbook = utils.book_new();

      // Append the worksheet to the workbook
      utils.book_append_sheet(workbook, worksheet, "Claims");

      // Write the file (download it on the browser)
      writeFile(workbook, "claims.xlsx", { bookType: "xlsx" });
    } catch (err) {
      console.error("Error exporting to XLS", err);
    }
  };

  return (
    <>
      <TableContainer style={{ background: "#fff" }}>
        <Table stickyHeader style={{ maxHeight: 400, overflow: "hidden" }}>
          <TableHeader creditors={allCreditors} />
          <TableBody>
            {groupedClaims.map(([date, data]) => (
              <TableRowComponent
                key={date}
                date={date}
                creditors={allCreditors}
                totals={data.creditors}
                dailyTotal={data.total}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        size="small"
        onClick={() => exportXls()}
        label="Export to Excel"
      />
    </>
  );
}

// Type definition for the grouped claims
interface CreditorProfits {
  [creditor: string]: number; // creditor name as key and their total profit as value
}

interface GroupedData {
  creditors: CreditorProfits;
  total: number; // total profit for the day
}

function groupClaimsByDayAndCreditor(
  claims: any[],
  currentMonth?: number,
  currentYear?: number
): [string, GroupedData][] {
  const currentDate = new Date();
  currentMonth = currentMonth || currentDate.getMonth(); // 0-based index (January is 0)
  currentYear = currentYear || currentDate.getFullYear();

  const grouped = claims.reduce((acc, claim) => {
    const claimDate = new Date(claim.lastPaymentDate);
    const paymentDate = !isNaN(claimDate.getTime())
      ? claimDate.toISOString().split("T")[0]
      : new Date().toISOString().split("T")[0]; // Default to current date if invalid
    // Check if the claim is in the current month and year
    if (
      claimDate.getMonth() !== currentMonth ||
      claimDate.getFullYear() !== currentYear
    ) {
      return acc; // Skip claims not in the current month
    }

    const creditor = claim.creditor?.businessName || "Unknown Creditor"; // Handle missing creditor information
    if (!acc[paymentDate]) {
      acc[paymentDate] = { creditors: {}, total: 0 }; // Initialize date group with total
    }
    if (!acc[paymentDate].creditors[creditor]) {
      acc[paymentDate].creditors[creditor] = 0; // Initialize creditor sum for this day
    }
    acc[paymentDate].creditors[creditor] +=
      claim.totalProfit > 0 ? claim.totalProfit : 0;
    acc[paymentDate].total += claim.totalProfit > 0 ? claim.totalProfit : 0; // Add to total profit for the day
    return acc;
  }, {} as { [key: string]: GroupedData });

  // Filter and sort
  // @ts-ignore
  return Object.entries(grouped)
    .filter(([_, data]) => Object.keys((data as any).creditors).length > 0)
    .sort(
      ([dateA], [dateB]) =>
        new Date(dateB)?.getTime() - new Date(dateA)?.getTime()
    );
}

const PaymentUpload = () => {
  const authHeader = {
    headers: { Authorization: localStorage.getItem("credentials") || "" },
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const notify = useNotify();
  const uploadProps = {
    name: "file",
    multiple: true,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files && event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        setIsLoading(true);

        fetch(process.env.REACT_APP_SERVER_URL + "/api/payments/create/file", {
          method: "POST",
          body: formData,
          ...authHeader,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            if (responseJson) {
              const successItems = responseJson.filter((responseLine: any) =>
                responseLine.status.startsWith("Success")
              );
              const successCount = successItems.length;
              const failedCount = responseJson.filter(
                (responseLine: any) => responseLine.status === "Failed"
              ).length;
              const unknownCount = responseJson.filter(
                (responseLine: any) => responseLine.status === "Unknown"
              ).length;
              const duplicateCount = responseJson.filter(
                (responseLine: any) => responseLine.status === "Duplicate"
              ).length;

              if (failedCount > 0 || unknownCount > 0 || duplicateCount > 0) {
                notify(
                  `${responseJson.length} Payments uploaded. ${successCount} success, ${failedCount} failed, ${unknownCount} unknown, ${duplicateCount} duplicate`,
                  "warning",
                  null,
                  false
                );
              } else {
                notify(
                  `${successCount} Payments uploaded successfully`,
                  "success",
                  null,
                  false
                );
              }

              jsonExport(responseJson, { rowDelimiter: ";" }, (_err, csv) => {
                // fix encoding
                const BOM = "\uFEFF";
                downloadCSV(
                  `${BOM} ${csv}`,
                  "Payments" + new Date()?.toISOString()
                );
              });

              setIsLoading(false);

              if (successCount > 0) {
                const today = new Date();
                const SEPA = require("sepa");
                const sepaExportDoc = new SEPA.Document("pain.001.001.03");
                sepaExportDoc.grpHdr.id =
                  "debtist-profits-" + format(today, "dd-MM-yyyy");
                sepaExportDoc.grpHdr.created = new Date();
                sepaExportDoc.grpHdr.initiatorName =
                  AppConfig.bank.accountHolder;

                const info = sepaExportDoc.createPaymentInfo();
                info.requestedExecutionDate = new Date();
                info.debtorIBAN = AppConfig.bank.iban;
                info.debtorBIC = AppConfig.bank.bic;
                info.debtorName = AppConfig.bank.accountHolder;
                info.batchBooking = false;
                sepaExportDoc.addPaymentInfo(info);
                for (const item of successItems) {
                  const debtistPayment = currency(item.debtistPayment || "0", {
                    separator: ".",
                    decimal: ",",
                  }).value;

                  if (debtistPayment && debtistPayment > 0) {
                    const tx = info.createTransaction();
                    tx.creditorName =
                      AppConfig.bank.companyAccount.accountHolder;
                    tx.creditorIBAN = AppConfig.bank.companyAccount.iban;
                    tx.amount = debtistPayment;
                    tx.remittanceInfo = item.claimReference + " Umsatz";
                    tx.end2endId =
                      item.claimReference +
                      "-" +
                      format(today, "dd-MM-yyyy") +
                      "-1";
                    try {
                      info.addTransaction(tx);
                    } catch (e) {
                      console.error("issue in " + item.claimReference);
                      throw e;
                    }
                  }
                }

                let anchor = document.createElement("a");
                document.body.appendChild(anchor);
                const blobby = new Blob([sepaExportDoc.toString()], {
                  type: "text/xml;charset=utf8",
                });

                const objectUrl = window.URL.createObjectURL(blobby);
                anchor.href = objectUrl;
                anchor.download =
                  "debtist-profits-" + format(today, "dd-MM-yyyy");
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
              }
            }
          })
          .catch((e) => {
            notify("Upload failed", "error", null, false);
          });
      }
    },
  };

  return (
    <>
      <Typography variant="h6">
        Upload Bank file: {isLoading && <CircularProgress size={16} />}
      </Typography>
      <Input type="file" {...uploadProps} style={{ marginTop: "1rem" }} />
    </>
  );
};

const PayoutList = (props: {
  currentMonth: number | null;
  currentYear: number;
  currentDate: number | null;
}): React.ReactElement => {
  const [claims, setClaims] = useState<Claim[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [creditorFilterValues, setCreditorFilterValues] = useState<object[]>(
    []
  );
  const [activePayoutCreditorFilter, setActivePayoutCreditorFilter] =
    useState("");
  const [totals, setTotals] = useState({
    sum: 0,
    toBePaidOut: 0,
    paidOut: 0,
    income: 0,
    tax: 0,
    totalProfit: 0,
  });

  const { data: unconfirmedPaymentData } = useGetList<Payment>(
    "Payment",
    { page: 1, perPage: 25 },
    { field: "paymentDate", order: "DESC" },
    {
      isPlanned: {
        equals: true,
      },
      paymentType: "DebtClearance",
    }
  );
  const unconfirmedPayments: Payment[] = Object.values(
    unconfirmedPaymentData
  ) as Payment[];

  useEffect(() => {
    let url = process.env.REACT_APP_SERVER_URL + "/api/payments/overview?";
    if (activePayoutCreditorFilter) {
      url = url + "creditor[id]=" + activePayoutCreditorFilter + "&";
    }
    if (props.currentYear !== null && props.currentMonth !== null) {
      const maximumDate = new Date(
        props.currentYear,
        (props.currentDate
          ? -1 + props.currentMonth
          : props.currentMonth || 0) + 1,
        (props.currentDate || 0) + 1,
        23,
        59
      );
      maximumDate.setDate(maximumDate.getDate() - 1);
      url = url + "payments[some][paymentDate][lte]=" + maximumDate + "&";
      url =
        url +
        "payments[some][paymentDate][gte]=" +
        new Date(props.currentYear, props.currentMonth || 0, 1, 0, 0);
    } else if (props.currentYear !== null) {
      const maximumDate = new Date(props.currentYear, 11, 31, 23, 59);
      url = url + "payments[some][paymentDate][lte]=" + maximumDate + "&";
      url =
        url +
        "payments[some][paymentDate][gte]=" +
        new Date(props.currentYear, 0, 1, 0, 0);
    }

    setIsLoading(true);
    const authHeader = {
      headers: { Authorization: localStorage.getItem("credentials") || "" },
    };
    fetch(url, authHeader)
      .then((response) => response.json())
      .then((responseJson) => {
        setClaims(responseJson);
        setIsLoading(false);
      });
  }, [
    activePayoutCreditorFilter,
    props.currentMonth,
    props.currentYear,
    props.currentDate,
  ]);

  useEffect(() => {
    const totalIncomingSum = claims?.reduce(function (a, b: any) {
      return a + (b.incomingPaymentsTotal > 0 ? b.incomingPaymentsTotal : 0);
    }, 0);

    const toBePaidOutSum = claims?.reduce(function (a, b: any) {
      return a + (b.toBePaidOutTotal > 0 ? b.toBePaidOutTotal : 0);
    }, 0);

    const paidOutSum = claims?.reduce(function (a, b: any) {
      return a + (b.paidOutTotal > 0 ? b.paidOutTotal : 0);
    }, 0);

    const taxSum = claims?.reduce(function (a, b: any) {
      return a + (b.tax > 0 ? b.tax : 0);
    }, 0);

    const totalProfitSum = claims?.reduce(function (a, b: any) {
      return a + (b.totalProfit > 0 ? b.totalProfit : 0);
    }, 0);

    let newCreditorFilterValues = [{ label: "(none)", value: "" }];
    // Group creditors by their id
    const creditorMap: { [key: string]: { user: User; totalProfit: number } } =
      {};
    for (const claim of claims) {
      const creditorId = claim.creditor.id;
      if (creditorMap[creditorId]) {
        creditorMap[creditorId].totalProfit += (claim as any).totalProfit;
      } else {
        creditorMap[creditorId] = {
          user: claim.creditor,
          totalProfit: (claim as any).totalProfit,
        };
      }
    }

    // Convert the map to an array
    const creditorArray = Object.values(creditorMap);

    // Sort the array based on the sum of their claims' totalProfit
    creditorArray.sort((a, b) => b.totalProfit - a.totalProfit);

    // Convert the array to the desired format
    newCreditorFilterValues = newCreditorFilterValues.concat(
      creditorArray.map((creditor) => ({
        label: creditor.user.businessName || "",
        value: creditor.user.id || "",
      }))
    );

    if (!activePayoutCreditorFilter || newCreditorFilterValues.length > 2) {
      setCreditorFilterValues(newCreditorFilterValues);
    }

    setTotals({
      sum: totalIncomingSum,
      toBePaidOut: toBePaidOutSum,
      paidOut: paidOutSum,
      income: totalIncomingSum - toBePaidOutSum - paidOutSum - taxSum,
      tax: taxSum,
      totalProfit: totalProfitSum,
    });
  }, [activePayoutCreditorFilter, claims]);

  const incomeGoalStatus = (totals.income / INCOME_GOAL) * 100;

  return (
    <div style={{ width: "100%", maxWidth: 600 }}>
      <Grid container style={{ width: "100%", minWidth: "100%" }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" style={{ marginBottom: 10 }}>
            Progress {isLoading && <CircularProgress size={16} />}
          </Typography>
          <Typography variant="body1">
            📨 Collected:&nbsp;
            {Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(totals.sum)}
            <br />⏳ Pending payout:&nbsp;
            {Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(totals.toBePaidOut)}
            <br />
            💸 Paid out:&nbsp;
            {Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(totals.paidOut)}
            <br />
            🥷 VAT:&nbsp;
            {Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(totals.tax)}
            <br />
            <>
              <b>
                💰 Revenue:&nbsp;
                {Intl.NumberFormat("de-DE", {
                  style: "currency",
                  currency: "EUR",
                }).format(totals.income)}
              </b>
              <br />
              {((props.currentMonth !== null && props.currentYear) ||
                props.currentYear !== null) && (
                <>
                  💰 Revenue (only{" "}
                  {(props.currentMonth !== null
                    ? props.currentMonth + 1 + "/"
                    : "") + props.currentYear}
                  ):&nbsp;
                  {Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  }).format(totals.totalProfit)}
                </>
              )}
              <br />
              <LinearProgress
                variant="buffer"
                color={"secondary"}
                style={{ borderRadius: 4 }}
                value={Math.min(incomeGoalStatus, 100)}
              />
              <br />
              {"Target: "}
              <EmojiEvents
                style={{
                  height: 16,
                  marginBottom: -2,
                  color: theme.palette.secondary.main,
                  width: 16,
                }}
              />
              <b
                style={{
                  color: theme.palette.secondary.main,
                }}
              >
                {incomeGoalStatus.toFixed(2) + "%"}
              </b>
            </>
          </Typography>
          <SimpleForm
            style={{ minWidth: "80%", marginLeft: -16 }}
            toolbar={false}
            onSubmit={() => undefined}
          >
            <AutocompleteInput
              source="creditorId"
              alwaysOn
              label="Creditor Filter"
              value={activePayoutCreditorFilter}
              onSelect={(e) => {
                setActivePayoutCreditorFilter(e.value);
              }}
              clearAlwaysVisible
              choices={creditorFilterValues}
              optionText="label"
              optionValue="value"
            />
          </SimpleForm>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignContent: "flex-end",
            overflow: "hidden",
          }}
        >
          <div className="shineEffect">
            <figure style={{ marginLeft: 0, marginBottom: 0 }}>
              <img
                alt={INCOME_GOAL_TITLE}
                src={`${process.env.PUBLIC_URL}/Airbus-H125.jpeg`}
                style={{ width: 220, height: 160, borderRadius: 50 }}
              />
            </figure>
            <caption
              style={{
                display: "block",
                textAlign: "center",
                fontSize: 12,
                marginTop: 10,
              }}
            >
              {INCOME_GOAL_TITLE}
            </caption>
          </div>
        </Grid>
      </Grid>
      <div style={{ marginTop: 50 }}>
        {unconfirmedPayments && unconfirmedPayments.length > 0 && (
          <>
            <Typography variant="h6">Unconfirmed payments</Typography>
            <ul style={{ listStyle: "none", padding: 0 }}>
              {unconfirmedPayments &&
                unconfirmedPayments.map((payment: Payment) => {
                  return (
                    <li
                      key={payment.id}
                      id={payment.id}
                      style={{ marginBottom: 20 }}
                    >
                      <a href={"#/Payment/" + payment.id + "/show"}>
                        {payment.id}:
                      </a>
                      {" " +
                        Intl.NumberFormat("de-DE", {
                          style: "currency",
                          currency: "EUR",
                        }).format(payment.amount || 0)}
                    </li>
                  );
                })}
            </ul>
            <br />
            <Divider />
            <br />
          </>
        )}
        <Typography variant="h6">Revenue per day:</Typography>
        <GroupedClaimsTable
          claims={claims}
          currentMonth={props.currentMonth}
          currentYear={props.currentYear}
        />
        <br />
        <br />
        <Typography variant="h6">Pending payout:</Typography>
        <ul style={{ listStyle: "none", padding: 0 }}>
          {claims &&
            claims.length > 0 &&
            claims.map((claim: Claim & { toBePaidOutTotal?: number }) => {
              if (
                claim.toBePaidOutTotal &&
                claim.toBePaidOutTotal > 0 &&
                claim.status !== "Closed"
              ) {
                return (
                  <li key={claim.id} id={claim.id} style={{ marginBottom: 20 }}>
                    <a href={"#/Claim/" + claim.id + "/show/1"}>
                      {claim.title && claim.reference}:
                    </a>
                    {" " +
                      Intl.NumberFormat("de-DE", {
                        style: "currency",
                        currency: "EUR",
                      }).format(claim.toBePaidOutTotal || 0)}
                  </li>
                );
              }
              return null;
            })}
        </ul>
      </div>
    </div>
  );
};

const UnpaidList = (props: {
  currentMonth: number | null;
  currentYear: number;
  currentDate: number | null;
}): React.ReactElement => {
  const [claims, setClaims] = useState<Claim[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [markForeignCurrencyPaid, setMarkForeignCurrencyPaid] =
    useState<boolean>(false);
  const [enableOnlinePayments, setEnableOnlinePayments] =
    useState<boolean>(false);
  const today = new Date();

  useEffect(() => {
    let url = process.env.REACT_APP_SERVER_URL + "/api/payments/unpaid?";
    if (props.currentYear && props.currentMonth) {
      const maximumDate = new Date(
        props.currentYear,
        (props.currentDate
          ? -1 + props.currentMonth
          : props.currentMonth || 0) + 1,
        (props.currentDate || 0) + 1,
        23,
        59
      );
      maximumDate.setDate(maximumDate.getDate() - 1);
      url = url + "payments[some][paymentDate][lte]=" + maximumDate + "&";
      url =
        url +
        "payments[some][paymentDate][gte]=" +
        new Date(props.currentYear, props.currentMonth || 0, 1, 0, 0);
    }

    setIsLoading(true);
    const authHeader = {
      headers: { Authorization: localStorage.getItem("credentials") || "" },
    };
    fetch(url, authHeader)
      .then((response) => response.json())
      .then((responseJson) => {
        setClaims(responseJson);
        setIsLoading(false);
      });
  }, [props.currentMonth, props.currentYear, props.currentDate]);

  const SEPA = require("sepa");
  const sepaExportDoc = new SEPA.Document("pain.001.001.03");
  sepaExportDoc.grpHdr.id = "debtist-payouts-" + format(today, "dd-MM-yyyy");
  sepaExportDoc.grpHdr.created = new Date();
  sepaExportDoc.grpHdr.initiatorName = AppConfig.bank.accountHolder;

  const info = sepaExportDoc.createPaymentInfo();
  info.requestedExecutionDate = new Date();
  info.debtorIBAN = AppConfig.bank.iban;
  info.debtorBIC = AppConfig.bank.bic;
  info.debtorName = AppConfig.bank.accountHolder;
  info.batchBooking = false;
  sepaExportDoc.addPaymentInfo(info);

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const foreignCurrencyClaims = [];

  return (
    <div style={{ minHeight: 305 }}>
      <Typography style={{ marginBottom: 25 }} variant="h6">
        Pending bank transfer: {isLoading && <CircularProgress size={12} />}
      </Typography>
      <ul style={{ listStyle: "none", padding: 0 }}>
        {claims &&
          claims.length > 0 &&
          claims.map((claim: Claim) => {
            const paymentInformation =
              claim.creditor?.paymentInformations.filter(
                (paymentInformation) =>
                  paymentInformation.currency === "EUR" ||
                  !paymentInformation.currency
              )[0];

            return claim.payments
              ?.filter(
                (payment) =>
                  payment.paymentType === "Payout" && payment.amount !== 0
              )
              ?.map((payment: Payment) => {
                const hasOnlinePayment = claim.payments?.some(
                  (payment: Payment) => {
                    return payment.reference
                      ?.toLowerCase()
                      .includes("online payment");
                  }
                );

                if (
                  paymentInformation &&
                  (!hasOnlinePayment || enableOnlinePayments)
                ) {
                  if (
                    (!claim.currency || claim.currency === "EUR") &&
                    startsWithAnyTwoLetters(paymentInformation.account)
                  ) {
                    const tx = info.createTransaction();
                    tx.creditorName = claim.creditor?.businessName;
                    tx.creditorIBAN =
                      paymentInformation?.account?.replaceAll(" ", "") || "";
                    tx.amount = payment.amount;
                    tx.remittanceInfo =
                      payment.reference?.replaceAll("_", "-") +
                      " - " +
                      claim.reference +
                      ": " +
                      (claim.title.length > 85
                        ? claim.title.replaceAll("_", "-").substring(0, 85) +
                          "..."
                        : claim.title?.replaceAll("_", "-"));
                    tx.end2endId =
                      claim.reference +
                      "-" +
                      payment.reference?.replaceAll("_", "-") +
                      "-1";
                    try {
                      info.addTransaction(tx);
                    } catch (e) {
                      console.error("issue in " + claim.reference);
                      throw e;
                    }
                  } else {
                    const foreignCurrencyPaymentInformation =
                      claim.creditor?.paymentInformations.filter(
                        (paymentInformation) =>
                          paymentInformation.currency === claim.currency ||
                          !paymentInformation.currency
                      )[0];

                    foreignCurrencyClaims.push({
                      creditorName: claim.creditor?.businessName,
                      creditorIBAN:
                        foreignCurrencyPaymentInformation?.account?.replaceAll(
                          " ",
                          ""
                        ) || "",
                      creditorBIC:
                        foreignCurrencyPaymentInformation?.bankIdentifier?.replaceAll(
                          " ",
                          ""
                        ) || "",
                      amountInEur: payment.amount,
                      currency: claim.currency,
                      remittanceInfo:
                        payment.reference?.replaceAll("_", "-") +
                        " - " +
                        claim.reference +
                        ": " +
                        (claim.title.length > 85
                          ? claim.title.replaceAll("_", "-").substring(0, 85) +
                            "..."
                          : claim.title?.replaceAll("_", "-")),
                      creditorCountry: claim.creditor?.address?.country
                        ? searchMapByKey(
                            ADDRESS_COUNTRY_SHORT,
                            claim.creditor?.address
                              .country as EnumAddressCountry
                          )
                        : "",
                      creditorAddressLine1:
                        claim.creditor?.address?.addressLine1,
                      creditorAddressLine2:
                        claim.creditor?.address?.addressLine2,
                      creditorCity: claim.creditor?.address?.town,
                      creditorPostalcode: claim.creditor?.address?.postalcode,
                      date: format(payment.paymentDate, "dd.MM.yyyy"),
                    });
                  }
                }

                return (
                  <li
                    key={payment.id}
                    id={payment.id}
                    style={{ marginBottom: 20 }}
                  >
                    <Typography variant="subtitle2">
                      <a href={"#/Payment/" + payment.id + "/show"}>
                        {claim.title && claim.reference}
                      </a>
                      {": " + payment.reference}
                      {" " + claim.creditor?.businessName}
                      {" " +
                        Intl.NumberFormat("de-DE", {
                          style: "currency",
                          currency: "EUR",
                        }).format(payment.amount || 0)}
                      {!paymentInformation && (
                        <span
                          style={{
                            fontWeight: 600,
                            color: theme.palette.error.main,
                          }}
                        >
                          {" No payment info"}
                        </span>
                      )}
                      {paymentInformation &&
                        !startsWithAnyTwoLetters(
                          paymentInformation.account
                        ) && (
                          <span
                            style={{
                              fontWeight: 600,
                              color: theme.palette.warning.main,
                            }}
                          >
                            {" Non-SEPA bank account"}
                          </span>
                        )}
                      {claim.currency && claim.currency !== "EUR" && (
                        <span
                          style={{
                            fontWeight: 600,
                            color: theme.palette.warning.dark,
                          }}
                        >
                          {" (" + claim.currency + ")"}
                        </span>
                      )}
                      {!hasOnlinePayment ? (
                        <AccountBalance
                          style={{
                            height: 20,
                            marginLeft: 4,
                            verticalAlign: "middle",
                            color: theme.palette.success.main,
                          }}
                        />
                      ) : (
                        <CreditCard
                          style={{
                            height: 20,
                            marginLeft: 4,
                            verticalAlign: "middle",
                            color: theme.palette.info.main,
                          }}
                        />
                      )}
                    </Typography>
                  </li>
                );
              });
          })}
      </ul>
      {claims && claims.length > 0 && (
        <>
          <Button
            label="Download SEPA file"
            style={{ marginTop: 10, marginRight: 10 }}
            onClick={() => {
              let anchor = document.createElement("a");
              document.body.appendChild(anchor);
              const blobby = new Blob([sepaExportDoc.toString()], {
                type: "text/xml;charset=utf8",
              });

              const objectUrl = window.URL.createObjectURL(blobby);
              anchor.href = objectUrl;
              anchor.download =
                "debtist-payouts-" + format(today, "dd-MM-yyyy");
              anchor.click();

              window.URL.revokeObjectURL(objectUrl);
            }}
          />
          <FormControlLabel
            label="Include online payments"
            value={enableOnlinePayments}
            onChange={(_e, checked) => {
              setEnableOnlinePayments(checked);
            }}
            control={
              <Switch color="secondary" defaultChecked={enableOnlinePayments} />
            }
            labelPlacement="end"
            style={{ marginRight: 10 }}
          />
          <br />
          <Button
            label="Mark all paid"
            variant="outlined"
            size="large"
            style={{ marginTop: 10 }}
            onClick={async () => {
              if (window.confirm("Do you really want to mark them as paid?")) {
                let successCount = 0;
                for (const claim of claims) {
                  const hasOnlinePayment = claim.payments?.some(
                    (payment: Payment) => {
                      return payment.reference
                        ?.toLowerCase()
                        .includes("online payment");
                    }
                  );

                  const paymentInformation =
                    claim.creditor?.paymentInformations.filter(
                      (paymentInformation) =>
                        paymentInformation.currency === "EUR" ||
                        !paymentInformation.currency
                    )[0];

                  if (
                    paymentInformation &&
                    startsWithAnyTwoLetters(paymentInformation.account) &&
                    (claim.currency === "EUR" || !claim.currency) &&
                    (!hasOnlinePayment || enableOnlinePayments)
                  ) {
                    for (const payment of claim.payments.filter(
                      (payment) => payment.paymentType === "Payout"
                    )) {
                      if (
                        await dataProvider.update("Payment", {
                          id: payment.id,
                          data: {
                            paymentType: "Payout", // required to trigger the notification
                            isPlanned: false,
                          },
                          previousData: payment,
                        })
                      ) {
                        successCount++;
                      }
                    }
                  }
                }
                notify(
                  `${successCount} Payments marked done`,
                  "success",
                  null,
                  false
                );
              }
            }}
          />
          <br />
          <Button
            label="Download CSV (FX)"
            style={{ marginTop: 10 }}
            onClick={() => {
              if (foreignCurrencyClaims.length > 0) {
                jsonExport(
                  foreignCurrencyClaims,
                  { rowDelimiter: ";" },
                  (_err, csv) => {
                    // fix encoding
                    const BOM = "\uFEFF";
                    downloadCSV(
                      `${BOM} ${csv}`,
                      "Payments" + new Date()?.toISOString()
                    );
                  }
                );
                setMarkForeignCurrencyPaid(true);
              }
            }}
          />

          {markForeignCurrencyPaid && (
            <Button
              label="Mark foreign currency claims paid"
              variant="outlined"
              size="large"
              style={{ marginTop: 10, marginLeft: 10 }}
              onClick={async () => {
                if (
                  window.confirm("Do you really want to mark them as paid?")
                ) {
                  let successCount = 0;
                  for (const claim of claims) {
                    const paymentInformation =
                      claim.creditor?.paymentInformations.filter(
                        (paymentInformation) =>
                          paymentInformation.currency === claim.currency ||
                          !paymentInformation.currency
                      )[0];

                    if (
                      paymentInformation &&
                      claim.currency &&
                      claim.currency !== "EUR"
                    ) {
                      for (const payment of claim.payments) {
                        if (
                          await dataProvider.update("Payment", {
                            id: payment.id,
                            data: {
                              isPlanned: false,
                            },
                            previousData: payment,
                          })
                        ) {
                          successCount++;
                        }
                      }
                    }
                  }
                  notify(
                    `${successCount} Payments marked done`,
                    "success",
                    null,
                    false
                  );
                }
              }}
            />
          )}
          <br />
        </>
      )}
    </div>
  );
};

export default PaymentOverview;
