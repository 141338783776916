import { Card } from "@material-ui/core";
import React from "react";
import { ReferenceField, useRecordContext } from "react-admin";
import { EnumAddressCountry } from "../api/address/EnumAddressCountry";
import { theme } from "../theme/theme";
import { PhoneNumber } from "../user/UserShow";

export const ClaimPhoneNumbers = React.memo<any>(() => {
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        paddingRight: 15,
        paddingLeft: 15,
        alignItems: "center",
      }}
    >
      <ReferenceField
        link={false}
        label="Debtor"
        source="debtor.id"
        reference="User"
      >
        <PhoneNumberComponent />
      </ReferenceField>
    </div>
  );
});

/*
      <List
        resource="User"
        basePath="/User"
        exporter={false}
        title={" "}
        actions={false}
        filter={{
          claimsAsContact: { some: { id: { equals: record?.id } } },
        }}
        filterDefaultValues={{
          claimsAsContact: { some: { id: { equals: record?.id } } },
        }}
        pagination={null}
        sort={{ field: "userType", order: "DESC" }}
      >
        <ContactPhoneNumbers />
      </List>

const ContactPhoneNumbers = () => {
  const { data } = useListContext();

  console.log("data", data);

  return (
    <span>
      {Object.values(data)?.map((item) => (
        <div>{item.phone}</div>
      ))}
    </span>
  );
};
*/

export const PhoneNumberComponent = (props: {
  inline?: boolean;
  country?: EnumAddressCountry;
  setShowAirCallPhone?: React.Dispatch<React.SetStateAction<boolean>>;
  phoneRef?: any;
}) => {
  const record = useRecordContext();
  if (!record || !record.phone) {
    return null;
  }

  return (
    <Card
      variant="outlined"
      style={{
        background: theme.palette.grey[100],
        margin: props.inline ? 0 : 10,
        paddingLeft: 10,
        paddingRight: 10,
        display: "inline-block",
        verticalAlign: "middle",
      }}
    >
      <PhoneNumber
        country={props.country}
        setShowAirCallPhone={props.setShowAirCallPhone}
        phoneRef={props.phoneRef}
      />
    </Card>
  );
};
