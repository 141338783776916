import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Divider,
  Input,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  ConfirmationNumber,
  EditRounded,
  FileCopyOutlined,
  KeyboardArrowUp,
  LocalOfferOutlined,
  RemoveCircleRounded,
  SaveOutlined,
  UndoRounded,
  VisibilityOutlined,
  Warning,
} from "@material-ui/icons";
import currency from "currency.js";
import { format } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  EditButton,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { Address } from "../api/address/Address";
import {
  EnumAddressCountry,
  GERMAN_SPEAKING_COUNTRIES,
} from "../api/address/EnumAddressCountry";
import { Claim } from "../api/claim/Claim";
import {
  EnumPaymentPaymentType,
  NEGATIVE_PAYMENT_TYPES,
  THIRD_PARTY_PAYMENT_TYPES,
} from "../api/payment/EnumPaymentPaymentType";
import { Payment } from "../api/payment/Payment";
import { SpecificSuccessFeeItem, User } from "../api/user/User";
import {
  EURO_CURRENCY_FORMAT,
  PaymentCreate,
  SIMPLE_EURO_CURRENCY_FORMAT,
} from "../payment/PaymentCreate";
import { theme } from "../theme/theme";
import { calculateTaxValue } from "../util/ClaimUtils";
import { numberFormatEUR } from "../util/CurrencyUtil";
import { CustomSlider } from "./CustomSlider";
import AdditionalCostCard from "./cards/sidebarCards/AdditionalCosts";
import PaymentDataCard from "./cards/sidebarCards/PaymentDataCard";

const useStyles = makeStyles((theme) => ({
  hideOnMobile: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

export const ClaimShowSidebar = (props: any) => {
  const record: Claim = useRecordContext();

  const numberFormat = useMemo(
    () =>
      Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: record?.currency || "EUR",
      }),
    [record?.currency]
  );

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useStyles();

  const [creditor, setCreditor] = React.useState<User | null>(null);
  const [debtor, setDebtor] = React.useState<User | null>(null);
  const [payments, setPayments] = React.useState<Payment[]>([]);
  const [paymentDataLoaded, setPaymentDataLoaded] =
    React.useState<boolean>(false);
  const [showAmountDetails, setShowAmountDetails] =
    React.useState<boolean>(false);

  const [sliderFactorCreditor, setSliderFactorCreditor] = useState(100);
  const [positionAmountToSave, setPositionAmountToSave] = useState("");
  const [positionIdToSave, setPositionIdToSave] = useState("");

  const [absoluteTotal, setAbsoluteTotal] = useState(0);
  const [showFactorCalculator, setShowFactorCalculator] = useState(false);
  const [sliderFactorDebtCollector, setSliderFactorDebtCollector] =
    useState(100);

  const handleChangeFactorCreditor = (_event, newValue) => {
    setSliderFactorCreditor(newValue);
  };
  const handleChangeFactorDebtCollector = (_event, newValue) => {
    setSliderFactorDebtCollector(newValue);
  };

  const fetchData = useCallback(
    async (
      entityName: string,
      id: string,
      setter: React.Dispatch<React.SetStateAction<any>>
    ) => {
      try {
        const { data } = await dataProvider.getOne(entityName, { id });
        setter(data);
      } catch (error) {
        console.log(error);
      }
    },
    [dataProvider]
  );

  useEffect(() => {
    if (record?.creditor?.id) {
      fetchData("User", record.creditor.id, setCreditor);
    }
  }, [record?.creditor?.id, fetchData]);

  useEffect(() => {
    if (record?.debtor?.id) {
      fetchData("User", record.debtor.id, setDebtor);
    }
  }, [record?.debtor?.id, fetchData]);

  const [creditorAddress, setCreditorAddress] = React.useState<Address | null>(
    null
  );

  useEffect(() => {
    if (creditor?.address?.id) {
      fetchData("Address", creditor.address.id, setCreditorAddress);
    }
  }, [creditor?.address?.id, fetchData]);

  const [debtorAddress, setDebtorAddress] = useState<Address | null>(null);

  const [showDebtCollectorFeeAndExpenses, setShowDebtCollectorFeeAndExpenses] =
    useState<boolean>(false);

  useEffect(() => {
    if (debtor?.address?.id) {
      fetchData("Address", debtor.address.id, setDebtorAddress);
    }
  }, [debtor?.address?.id, fetchData]);

  React.useEffect(() => {
    if (props.claimId) {
      dataProvider
        .getList<Payment>("Payment", {
          filter: { claim: { id: props.claimId } },
          pagination: {
            page: 1,
            perPage: 500,
          },
          sort: { field: "paymentDate", order: "DESC" },
        })
        .then(({ data }) => {
          setPayments(data as Payment[]);
          setPaymentDataLoaded(true);
        })
        .catch((error) => {
          console.log(error);
          setPaymentDataLoaded(true);
        });
    }
  }, [props.claimId, record, dataProvider, refresh]);

  // latest costs
  const latestDunningCostExpense = payments.find(
    (payment) =>
      payment.paymentType === EnumPaymentPaymentType.DunningCostExpense
  );
  const latestDunningCostFee = payments.find(
    (payment) => payment.paymentType === EnumPaymentPaymentType.DunningCostFee
  );
  const latestDefaultInterest = payments.find(
    (payment) => payment.paymentType === EnumPaymentPaymentType.DefaultInterest
  );
  const latestCreditorExtra = payments.find(
    (payment) =>
      payment.paymentType === EnumPaymentPaymentType.ExistingCreditorExtras
  );
  const latestCreditorInterest = payments.find(
    (payment) => payment.paymentType === EnumPaymentPaymentType.CreditorInterest
  );
  const latestDebtCollectorTax = payments.find(
    (payment) => payment.paymentType === EnumPaymentPaymentType.DebtCollectorTax
  );

  const incomingPayments = payments.filter(
    (payment) =>
      payment.paymentType &&
      NEGATIVE_PAYMENT_TYPES.includes(
        payment.paymentType as EnumPaymentPaymentType
      ) &&
      payment.paymentType !== EnumPaymentPaymentType.WriteOffDiscount &&
      payment.paymentType !== EnumPaymentPaymentType.ExistingPayments &&
      !THIRD_PARTY_PAYMENT_TYPES.includes(
        payment.paymentType as EnumPaymentPaymentType
      )
  );
  const incomingPaymentsTotal: number = parseFloat(
    incomingPayments
      .reduce(function (a, b) {
        return a + b.amount;
      }, 0)
      ?.toFixed(2)
  );

  const writeOffs = payments.filter(
    (payment) => payment.paymentType === EnumPaymentPaymentType.WriteOffDiscount
  );
  const writeOffsTotal: number = parseFloat(
    writeOffs
      .reduce(function (a, b) {
        return a + b.amount;
      }, 0)
      ?.toFixed(2)
  );

  const existingPayments = payments.filter(
    (payment) => payment.paymentType === EnumPaymentPaymentType.ExistingPayments
  );
  const existingPaymentsTotal: number = parseFloat(
    existingPayments
      .reduce(function (a, b) {
        return a + b.amount;
      }, 0)
      ?.toFixed(2)
  );

  const claimPositions = payments.filter(
    (payment) =>
      payment.paymentType &&
      payment.paymentType === EnumPaymentPaymentType.ClaimPosition
  );
  let claimPositionsTotal: number = parseFloat(
    claimPositions
      .filter((payment) => !payment.isPlanned)
      .reduce(function (a, b) {
        return a + b.amount;
      }, 0)
      ?.toFixed(2)
  );

  const payouts = payments.filter(
    (payment) =>
      payment.paymentType === EnumPaymentPaymentType.Payout &&
      payment.amount >= 0
  );
  const payoutsTotal: number = parseFloat(
    payouts
      .reduce(function (a, b) {
        return a + b.amount;
      }, 0)
      ?.toFixed(2)
  );

  const negativePayouts = payments.filter(
    (payment) =>
      payment.paymentType === EnumPaymentPaymentType.Payout &&
      payment.amount < 0
  );

  const debtCollectorFees = payments.filter(
    (payment) =>
      payment.paymentType === EnumPaymentPaymentType.DebtCollectorFee &&
      payment.amount >= 0
  );
  const debtCollectorFeesTotal: number = parseFloat(
    debtCollectorFees
      .reduce(function (a, b) {
        return a + b.amount;
      }, 0)
      ?.toFixed(2)
  );

  const latestPayout: Payment = payments.find(
    (payment) => payment.paymentType === EnumPaymentPaymentType.Payout
  );
  const latestActualPayout = payments.find(
    (payment) =>
      payment.paymentType === EnumPaymentPaymentType.Payout &&
      payment.amount > 0
  );
  const today = Date.now();
  let latestPayoutDate: number = today;
  let latestActualPayoutDate: number = today;
  if (latestPayout?.paymentDate) {
    latestPayoutDate = Date.parse(latestPayout?.paymentDate?.toString());
  }
  if (latestActualPayout?.paymentDate) {
    latestActualPayoutDate = Date.parse(
      latestActualPayout?.paymentDate?.toString()
    );
  }

  // everything together
  useEffect(() => {
    setAbsoluteTotal(
      parseFloat(
        (
          (record?.originalAmountDue || 0) * (sliderFactorCreditor / 100) +
          (latestDunningCostFee?.amount || 0) *
            (sliderFactorDebtCollector / 100) +
          (latestDunningCostExpense?.amount || 0) *
            (sliderFactorDebtCollector / 100) +
          (latestDefaultInterest?.amount || 0) *
            (sliderFactorDebtCollector / 100) +
          (latestCreditorExtra?.amount || 0) *
            (sliderFactorDebtCollector / 100) +
          (latestCreditorInterest?.amount || 0) * (sliderFactorCreditor / 100) +
          (latestDebtCollectorTax?.amount || 0) *
            (sliderFactorDebtCollector / 100) +
          existingPaymentsTotal +
          writeOffsTotal
        )?.toFixed(2)
      )
    );
    props.setAbsoluteTotal(absoluteTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    record?.originalAmountDue,
    sliderFactorCreditor,
    latestDunningCostFee?.amount,
    sliderFactorDebtCollector,
    latestDunningCostExpense?.amount,
    latestDefaultInterest?.amount,
    latestCreditorExtra?.amount,
    latestCreditorInterest?.amount,
    latestDebtCollectorTax?.amount,
    existingPaymentsTotal,
    writeOffsTotal,
    absoluteTotal,
  ]);

  if (!record) {
    return null;
  }

  let currentTotal: number = parseFloat(
    (absoluteTotal + incomingPaymentsTotal)?.toFixed(2)
  );

  let paymentsAfterLastPayout =
    latestActualPayoutDate !== today
      ? payments.filter(
          (payment) =>
            (payment.paymentType === EnumPaymentPaymentType.DebtClearance ||
              payment.paymentType === EnumPaymentPaymentType.PaymentRate) &&
            Date.parse(payment.paymentDate?.toString()) > latestPayoutDate
        )
      : payments.filter(
          (payment) =>
            payment.paymentType === EnumPaymentPaymentType.DebtClearance ||
            payment.paymentType === EnumPaymentPaymentType.PaymentRate
        );
  const paymentsAfterLastPayoutTotal = parseFloat(
    Math.abs(
      paymentsAfterLastPayout.reduce(function (a, b) {
        return a + b.amount;
      }, 0)
    )?.toFixed(2)
  );

  let currentClaimSumMinusCosts = parseFloat(
    (Math.abs(incomingPaymentsTotal) - payoutsTotal)?.toFixed(2)
  );

  let deductDunningCostExpenseFromPendingPayout = false;
  let deductDunningCostFeeFromPendingPayout = false;

  let debtistCostsTotal: number = parseFloat(
    (
      (latestCreditorExtra?.amount || 0) +
      (latestDefaultInterest?.amount || 0) +
      (latestDunningCostFee?.amount || 0) +
      (latestDunningCostExpense?.amount || 0)
    )?.toFixed(2)
  );

  let debtistTaxTotal: number = 0;

  if (debtistCostsTotal > 0 && creditorAddress?.country === "DEU") {
    debtistTaxTotal = calculateTaxValue(debtistCostsTotal, 0.19);

    debtistCostsTotal = debtistCostsTotal + debtistTaxTotal;
  }

  currentClaimSumMinusCosts = parseFloat(
    (
      parseFloat(
        (
          (currentClaimSumMinusCosts -
            (latestCreditorExtra?.amount || 0) -
            (latestDefaultInterest?.amount || 0) -
            (latestDunningCostFee?.amount || 0) -
            (latestDunningCostExpense?.amount || 0) -
            (latestDebtCollectorTax?.amount || 0)) *
          100
        )?.toFixed(0)
      ) / 100
    )?.toFixed(2)
  );

  let toBePaidOutTotal: number =
    record?.stage !== "Reminder" ? currentClaimSumMinusCosts : 0;
  if (
    toBePaidOutTotal > 0 &&
    !latestDebtCollectorTax?.amount &&
    creditorAddress?.country === "DEU"
  ) {
    toBePaidOutTotal = parseFloat(
      (
        parseFloat(((toBePaidOutTotal - debtistTaxTotal) * 100)?.toFixed(0)) /
        100
      )?.toFixed(2)
    );
  }

  let relevantSuccessFee: number = 0;
  let successFeeTax: number = 0;
  let isAvailableForSuccessFee: boolean = false;
  let deductDefaultInterestFromPendingPayout: boolean = false;
  let deductExistingCreditorExtrasFromPendingPayout: boolean = false;
  let debtistCostsPending: number = 0;
  let debtistTaxPending: number = 0;

  const paymentsBeforeLastPayout = payments.filter(
    (payment) =>
      (payment.paymentType === EnumPaymentPaymentType.DebtClearance ||
        payment.paymentType === EnumPaymentPaymentType.PaymentRate) &&
      Date.parse(payment.paymentDate?.toString()) < latestPayoutDate
  );

  const paymentsBeforeLastPayoutTotal = parseFloat(
    Math.abs(
      paymentsBeforeLastPayout.reduce(function (a, b) {
        return a + b.amount;
      }, 0)
    )?.toFixed(2)
  );

  if (!latestPayout || debtistCostsTotal > paymentsBeforeLastPayoutTotal) {
    // no payouts yet, always deduct
    if (!latestPayout) {
      deductExistingCreditorExtrasFromPendingPayout = true;
      deductDunningCostExpenseFromPendingPayout = true;
      deductDunningCostFeeFromPendingPayout = true;
      deductDefaultInterestFromPendingPayout = true;

      debtistCostsPending = debtistCostsTotal;
    } else {
      // fees not yet paid in total, maybe partly
      if (
        (latestDefaultInterest?.amount || 0) > paymentsBeforeLastPayoutTotal
      ) {
        deductDefaultInterestFromPendingPayout = true;
      }
      if (
        (latestDefaultInterest?.amount || 0) +
          (latestDunningCostExpense?.amount || 0) >
        paymentsBeforeLastPayoutTotal
      ) {
        deductDunningCostExpenseFromPendingPayout = true;
      }
      if (
        (latestDefaultInterest?.amount || 0) +
          (latestDunningCostExpense?.amount || 0) +
          (latestDunningCostFee?.amount || 0) >
        paymentsBeforeLastPayoutTotal
      ) {
        deductDunningCostFeeFromPendingPayout = true;
      }
      if (
        (latestCreditorExtra?.amount || 0) +
          (latestDunningCostExpense?.amount || 0) +
          (latestDunningCostFee?.amount || 0) +
          (latestCreditorExtra?.amount || 0) >
        paymentsBeforeLastPayoutTotal
      ) {
        deductExistingCreditorExtrasFromPendingPayout = true;
      }

      debtistCostsPending = Math.max(
        0,
        debtistCostsTotal - paymentsBeforeLastPayoutTotal
      );
      if (creditorAddress?.country === "DEU") {
        debtistTaxPending = calculateTaxValue(debtistCostsPending, 0.19);
      }
    }
  }

  const customSuccessFee =
    Array.isArray(creditor?.specificSuccessFeeInPercent) &&
    creditor?.specificSuccessFeeInPercent?.length &&
    (
      creditor?.specificSuccessFeeInPercent as unknown as SpecificSuccessFeeItem[]
    )?.find((item) => item.key === debtorAddress?.country)?.value;
  let successFeePercentage =
    customSuccessFee ||
    (debtorAddress?.country &&
    !GERMAN_SPEAKING_COUNTRIES.includes(
      debtorAddress.country as EnumAddressCountry
    )
      ? creditor?.successFeeInPercent
      : 0) ||
    0;
  let relevantTotalForSuccessFee = Math.min(
    currentClaimSumMinusCosts,
    paymentsAfterLastPayoutTotal
  );

  if (
    successFeePercentage &&
    debtorAddress?.country &&
    !GERMAN_SPEAKING_COUNTRIES.includes(
      debtorAddress.country as EnumAddressCountry
    )
  ) {
    isAvailableForSuccessFee = true;
    if (paymentsAfterLastPayoutTotal > 0) {
      relevantSuccessFee = parseFloat(
        (
          relevantTotalForSuccessFee *
          parseFloat((successFeePercentage / 100).toFixed(2))
        ).toFixed(2)
      );
    } else {
      relevantSuccessFee = debtCollectorFeesTotal;
    }
  }

  if (relevantSuccessFee > 0) {
    if (creditorAddress?.country === "DEU" && relevantSuccessFee) {
      successFeeTax = calculateTaxValue(relevantSuccessFee, 0.19);
    }
  }

  if (toBePaidOutTotal > 0) {
    toBePaidOutTotal = parseFloat(
      (
        parseFloat(
          (
            (toBePaidOutTotal -
              debtCollectorFeesTotal -
              calculateTaxValue(debtCollectorFeesTotal, 0.19) -
              relevantSuccessFee -
              successFeeTax) *
            100
          )?.toFixed(0)
        ) / 100
      )?.toFixed(2)
    );
  }

  const currentCreditorAmountDue: number =
    (record?.originalAmountDue || 0) - Math.abs(existingPaymentsTotal);

  const currentCreditorAmountDueInclInterest: number =
    currentCreditorAmountDue + (latestCreditorInterest?.amount || 0);
  props.setCurrentCreditorAmountDueInclInterest(
    currentCreditorAmountDueInclInterest
  );

  const isPaid: Boolean =
    paymentDataLoaded &&
    (record?.currency && record?.currency !== "EUR"
      ? record?.totalPending <= 0
      : currentTotal <= 0);

  function sliderFormat(value) {
    return `${value}%`;
  }

  return (
    <Box
      className={classes.hideOnMobile}
      style={{
        maxWidth: 260,
        width: "100%",
        margin: "0 0 0 1.5em",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "flex-start",
      }}
    >
      <ClaimEditActions
        setShowFactorCalculator={setShowFactorCalculator}
        showFactorCalculator={showFactorCalculator}
      />
      {showFactorCalculator && (
        <Card
          style={{
            marginBottom: 20,
          }}
        >
          <CardContent>
            <Typography
              variant="h6"
              style={{
                marginBottom: 10,
              }}
            >
              Vergleich
            </Typography>
            <Typography variant="caption">Gläubiger</Typography>
            <CustomSlider
              defaultValue={100}
              value={sliderFactorCreditor}
              onChange={handleChangeFactorCreditor}
              color="primary"
              valueLabelFormat={sliderFormat}
              step={1}
              valueLabelDisplay="auto"
            />
            <Typography variant="caption">Inkasso</Typography>
            <CustomSlider
              defaultValue={100}
              value={sliderFactorDebtCollector}
              onChange={handleChangeFactorDebtCollector}
              color="secondary"
              valueLabelFormat={sliderFormat}
              step={1}
              valueLabelDisplay="auto"
            />
          </CardContent>
        </Card>
      )}
      {paymentDataLoaded && (
        <Card
          style={{
            marginBottom: 20,
          }}
        >
          <CardContent>
            <Typography variant="h6">Balance</Typography>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            {record?.originalAmountDue &&
              currentCreditorAmountDueInclInterest !==
                record?.originalAmountDue && (
                <div className="flex-between">
                  <Typography variant="body2">Ursprungssumme:</Typography>
                  <Typography variant="body2">
                    {numberFormat.format(record?.originalAmountDue)}
                  </Typography>
                </div>
              )}
            {existingPaymentsTotal !== 0 && (
              <div className="flex-between">
                <Typography variant="body2" style={{ color: "grey" }}>
                  Gläubiger Zahlungen:
                </Typography>
                <Typography variant="body2" style={{ color: "grey" }}>
                  {numberFormat.format(existingPaymentsTotal)}
                </Typography>
              </div>
            )}
            {currentCreditorAmountDue !== record?.originalAmountDue &&
              currentCreditorAmountDue !==
                currentCreditorAmountDueInclInterest && (
                <div className="flex-between">
                  <Typography
                    variant="body2"
                    style={{ fontWeight: 600, color: "grey" }}
                  >
                    Zwischensumme:
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ fontWeight: 600, color: "grey" }}
                  >
                    {numberFormat.format(currentCreditorAmountDue)}
                  </Typography>
                </div>
              )}
            {record?.originalAmountDue && (
              <Accordion
                variant="outlined"
                square
                style={{
                  border: 0,
                  background: "transparent",
                  padding: 0,
                  margin: 0,
                }}
                expanded={showAmountDetails}
                onChange={() => {
                  setShowAmountDetails(!showAmountDetails);
                }}
              >
                <AccordionSummary
                  style={{
                    padding: 0,
                    paddingTop: 10,
                    paddingBottom: 10,
                    margin: 0,
                    userSelect: "text",
                    width: "100%",
                  }}
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <div className="flex-between">
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: 600,
                        position: "relative",
                      }}
                    >
                      Hauptf. gesamt:
                      <Tooltip
                        title={
                          claimPositionsTotal &&
                          record?.originalAmountDue !== claimPositionsTotal
                            ? `Summen inkorrekt: ${record?.originalAmountDue} / ${claimPositionsTotal}`
                            : ""
                        }
                      >
                        <KeyboardArrowUp
                          className={`forderung-arrow ${
                            showAmountDetails ? "-open" : ""
                          }`}
                          style={{
                            color:
                              claimPositionsTotal &&
                              record?.originalAmountDue !== claimPositionsTotal
                                ? theme.palette.error.main
                                : theme.palette.success.main,
                          }}
                        />
                      </Tooltip>
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      {`${numberFormat.format(
                        parseFloat(
                          (
                            currentCreditorAmountDueInclInterest *
                            (sliderFactorCreditor / 100)
                          ).toFixed(2)
                        )
                      )}`}
                    </Typography>
                  </div>
                </AccordionSummary>

                <AccordionDetails
                  style={{
                    padding: 0,
                    margin: 0,
                    width: "100%",
                    display: "inline-block",
                    wordBreak: "break-word",
                    whiteSpace: "pre-line",
                  }}
                >
                  <Typography variant="body2" align="right">
                    <TableContainer>
                      <Table size="small" style={{ marginBottom: 8 }}>
                        <TableBody>
                          {claimPositions.length > 0 ? (
                            claimPositions.map((position) => {
                              return (
                                <TableRow
                                  style={{
                                    ...(position.isPlanned && {
                                      textDecoration: "line-through",
                                    }),
                                  }}
                                >
                                  <TableCell
                                    component="th"
                                    variant="footer"
                                    size="small"
                                    style={{
                                      color: theme.palette.grey[600],
                                      lineHeight: "18px",
                                      padding: "6px 2px",
                                      maxWidth: 130,
                                      minWidth: 130,
                                      fontSize: 13,
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: 600,
                                        color: theme.palette.grey[900],
                                      }}
                                    >
                                      {position.reference?.replace(
                                        " (initial)",
                                        ""
                                      )}
                                    </div>
                                    <div>
                                      {format(
                                        new Date(position.paymentDate),
                                        "dd.MM.yy"
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        color: theme.palette.secondary.main,
                                        minWidth: 80,
                                        fontWeight: 600,
                                      }}
                                    >
                                      {numberFormat.format(position.amount)}
                                    </div>
                                  </TableCell>

                                  {position.id === positionIdToSave && (
                                    <TableCell
                                      padding="none"
                                      align="right"
                                      variant="footer"
                                      size="small"
                                      style={{
                                        color: theme.palette.primary.main,
                                        minWidth: 80,
                                      }}
                                    >
                                      <div>
                                        <Input
                                          value={positionAmountToSave?.toString()}
                                          onChange={(e) => {
                                            const newValue =
                                              e.target.value.replaceAll(
                                                /[^\d.]/g,
                                                ""
                                              );
                                            setPositionAmountToSave(newValue);
                                          }}
                                          style={{
                                            fontSize: 15,
                                            marginTop: 2,
                                          }}
                                          onPaste={async (e) => {
                                            e.preventDefault();
                                            let pasteContent =
                                              e.clipboardData.getData("text");
                                            if (pasteContent) {
                                              const parsedInput =
                                                EURO_CURRENCY_FORMAT.test(
                                                  pasteContent
                                                ) ||
                                                SIMPLE_EURO_CURRENCY_FORMAT.test(
                                                  pasteContent
                                                )
                                                  ? currency(
                                                      pasteContent
                                                        .replace(".", "")
                                                        .replace(",", "."),
                                                      {
                                                        separator: "",
                                                        decimal: ".",
                                                        symbol: "€",
                                                      }
                                                    ).value
                                                  : currency(pasteContent, {
                                                      separator: ",",
                                                      decimal: ".",
                                                      symbol: "$",
                                                    }).value;

                                              if (!isNaN(parsedInput)) {
                                                setPositionAmountToSave(
                                                  parsedInput.toString()
                                                );
                                              }
                                            }
                                          }}
                                          defaultValue={position.amount?.toString()}
                                        />
                                        <Tooltip title="Save">
                                          <Button
                                            onClick={async (e) => {
                                              e.stopPropagation();

                                              await dataProvider.update(
                                                "Claim",
                                                {
                                                  id: record?.id,
                                                  data: {
                                                    originalAmountDue:
                                                      parseFloat(
                                                        (
                                                          record?.originalAmountDue -
                                                          position.amount +
                                                          Number(
                                                            positionAmountToSave
                                                          )
                                                        ).toFixed(2)
                                                      ),
                                                  },
                                                  previousData: record,
                                                }
                                              );
                                              await dataProvider.update(
                                                "Payment",
                                                {
                                                  id: position.id,
                                                  data: {
                                                    amount:
                                                      Number(
                                                        positionAmountToSave
                                                      ),
                                                  },
                                                  previousData: position,
                                                }
                                              );
                                              refresh();
                                              setPositionIdToSave("");
                                            }}
                                            startIcon={<SaveOutlined />}
                                            label={"Save"}
                                            style={{
                                              backgroundColor:
                                                theme.palette.success.dark,
                                              minWidth: "100%",
                                              marginTop: 3,
                                              marginBottom: 5,
                                            }}
                                            size={"small"}
                                            variant={"contained"}
                                          />
                                        </Tooltip>
                                      </div>
                                    </TableCell>
                                  )}
                                  <TableCell
                                    padding="none"
                                    align="right"
                                    variant="footer"
                                    size="small"
                                  >
                                    {(!record?.currency ||
                                      record?.currency === "EUR") &&
                                    !position.isPlanned ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "self-end",
                                        }}
                                      >
                                        {position.id !== positionIdToSave && (
                                          <EditRounded
                                            style={{
                                              height: 16,
                                              cursor: "pointer",
                                              background:
                                                theme.palette.warning.dark,
                                              color: "#fff",
                                              padding: "5px 1px",
                                              borderRadius: 5,
                                              marginBottom: 5,
                                              verticalAlign: "middle",
                                              zIndex: 3,
                                            }}
                                            onClick={(e) => {
                                              e.stopPropagation();

                                              setPositionAmountToSave(
                                                position.amount?.toString()
                                              );
                                              setPositionIdToSave(position.id);
                                            }}
                                          />
                                        )}
                                        {position.id !== positionIdToSave && (
                                          <Tooltip title="Remove">
                                            <RemoveCircleRounded
                                              style={{
                                                height: 16,
                                                cursor: "pointer",
                                                background:
                                                  theme.palette.error.dark,
                                                color: "#fff",
                                                padding: "5px 1px",
                                                borderRadius: 5,
                                                marginLeft: -5,
                                                verticalAlign: "middle",
                                                zIndex: 3,
                                              }}
                                              onClick={async (e) => {
                                                e.stopPropagation();
                                                if (
                                                  window.confirm(
                                                    "Remove this position?"
                                                  )
                                                ) {
                                                  await dataProvider.update(
                                                    "Claim",
                                                    {
                                                      id: record?.id,
                                                      data: {
                                                        originalAmountDue:
                                                          parseFloat(
                                                            (
                                                              record?.originalAmountDue -
                                                              position.amount
                                                            ).toFixed(2)
                                                          ),
                                                        totalPending:
                                                          parseFloat(
                                                            (
                                                              record?.totalPending -
                                                              position.amount
                                                            ).toFixed(2)
                                                          ),
                                                      },
                                                      previousData: record,
                                                    }
                                                  );
                                                  await dataProvider.update(
                                                    "Payment",
                                                    {
                                                      id: position.id,
                                                      data: {
                                                        isPlanned: true,
                                                      },
                                                      previousData: position,
                                                    }
                                                  );
                                                  refresh();
                                                }
                                              }}
                                            />
                                          </Tooltip>
                                        )}
                                      </div>
                                    ) : (
                                      position.id !== positionIdToSave &&
                                      (!record?.currency ||
                                        record?.currency === "EUR") && (
                                        <Tooltip title="Undo">
                                          <UndoRounded
                                            style={{
                                              height: 16,
                                              cursor: "pointer",
                                              background:
                                                theme.palette.primary.dark,
                                              color: "#fff",
                                              padding: "5px 1px",
                                              borderRadius: 5,
                                              marginLeft: -5,
                                              verticalAlign: "middle",
                                              zIndex: 3,
                                            }}
                                            onClick={async (e) => {
                                              e.stopPropagation();
                                              if (
                                                window.confirm("Undo deletion?")
                                              ) {
                                                await dataProvider.update(
                                                  "Claim",
                                                  {
                                                    id: record?.id,
                                                    data: {
                                                      originalAmountDue:
                                                        parseFloat(
                                                          (
                                                            record?.originalAmountDue +
                                                            position.amount
                                                          ).toFixed(2)
                                                        ),
                                                      totalPending: parseFloat(
                                                        (
                                                          record?.totalPending +
                                                          position.amount
                                                        ).toFixed(2)
                                                      ),
                                                    },
                                                    previousData: record,
                                                  }
                                                );
                                                await dataProvider.update(
                                                  "Payment",
                                                  {
                                                    id: position.id,
                                                    data: {
                                                      isPlanned: false,
                                                    },
                                                    previousData: position,
                                                  }
                                                );
                                                position.isPlanned = false;
                                                refresh();
                                              }
                                            }}
                                          />
                                        </Tooltip>
                                      )
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          ) : (
                            <TableRow style={{}}>
                              <TableCell
                                component="th"
                                variant="footer"
                                size="small"
                                style={{
                                  color: theme.palette.grey[600],
                                  lineHeight: "18px",
                                }}
                              >
                                <div
                                  style={{
                                    color: theme.palette.grey[900],
                                  }}
                                >
                                  {record?.title}
                                </div>
                                <div>
                                  {format(
                                    new Date(record?.invoiceCreatedAt),
                                    "dd.MM.yy"
                                  )}
                                </div>
                              </TableCell>

                              <TableCell
                                padding="none"
                                align="right"
                                variant="footer"
                                size="small"
                                style={{
                                  color: theme.palette.primary.main,
                                  minWidth: 80,
                                }}
                              >
                                {record?.id === positionIdToSave ? (
                                  <div>
                                    <Input
                                      value={positionAmountToSave?.toString()}
                                      onChange={(e) => {
                                        const newValue =
                                          e.target.value.replaceAll(
                                            /[^\d.]/g,
                                            ""
                                          );
                                        setPositionAmountToSave(newValue);
                                      }}
                                      defaultValue={record.originalAmountDue?.toString()}
                                    />
                                    <Button
                                      onClick={async (e) => {
                                        e.stopPropagation();

                                        await dataProvider.update("Claim", {
                                          id: record?.id,
                                          data: {
                                            originalAmountDue: parseFloat(
                                              Number(
                                                positionAmountToSave
                                              ).toFixed(2)
                                            ),
                                            totalPending: parseFloat(
                                              (
                                                record?.totalPending -
                                                record?.originalAmountDue +
                                                Number(positionAmountToSave)
                                              ).toFixed(2)
                                            ),
                                          },
                                          previousData: record,
                                        });
                                        refresh();
                                        setPositionIdToSave("");
                                      }}
                                      startIcon={<SaveOutlined />}
                                      label={"Save"}
                                      style={{
                                        backgroundColor:
                                          theme.palette.success.dark,
                                        minWidth: "100%",
                                        marginTop: 3,
                                        marginBottom: 5,
                                      }}
                                      size={"small"}
                                      variant={"contained"}
                                    />
                                  </div>
                                ) : (
                                  numberFormat.format(record.originalAmountDue)
                                )}
                              </TableCell>

                              <TableCell
                                padding="none"
                                align="right"
                                variant="footer"
                                size="small"
                              >
                                {(!record?.currency ||
                                  record?.currency === "EUR") &&
                                  record?.id !== positionIdToSave && (
                                    <EditRounded
                                      style={{
                                        height: 16,
                                        cursor: "pointer",
                                        background: theme.palette.warning.dark,
                                        color: "#fff",
                                        padding: "5px 1px",
                                        borderRadius: 5,
                                        marginBottom: 5,
                                        verticalAlign: "middle",
                                        zIndex: 3,
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();

                                        setPositionAmountToSave(
                                          record.originalAmountDue.toString()
                                        );
                                        setPositionIdToSave(record.id);
                                      }}
                                    />
                                  )}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <hr />
                    <div
                      style={{
                        maxWidth: 250,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          fontWeight: 600,
                          textAlign: "left",
                          marginBottom: 0,
                          marginTop: 10,
                          color: theme.palette.grey[800],
                        }}
                      >
                        New position:
                      </Typography>
                      <PaymentCreate
                        basePath="/Payment"
                        resource="Payment"
                        options={{
                          inline: true,
                          claimId: record?.id,
                          paymentType: "ClaimPosition",
                        }}
                      />
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )}
            {latestCreditorInterest && (
              <div className="flex-between">
                <Typography variant="body2">Gläubigerzinsen: </Typography>
                <Typography variant="body2">
                  {numberFormat.format(
                    parseFloat(
                      (
                        (latestCreditorInterest.amount * sliderFactorCreditor) /
                        100
                      ).toFixed(2)
                    )
                  )}
                </Typography>
              </div>
            )}
            {writeOffsTotal !== 0 && (
              <div className="flex-between">
                <Typography variant="body2" style={{ color: "grey" }}>
                  Abschreibungen:
                </Typography>
                <Typography variant="body2" style={{ color: "grey" }}>
                  {numberFormatEUR.format(writeOffsTotal)}
                </Typography>
              </div>
            )}
            {payoutsTotal > 0 && (
              <>
                {(!record?.currency || record?.currency === "EUR") && (
                  <div className="flex-between">
                    <Typography variant="body2" style={{ fontWeight: 600 }}>
                      Hauptf. offen:{" "}
                    </Typography>
                    <Typography variant="body2" style={{ fontWeight: 600 }}>
                      {numberFormat.format(
                        Math.max(
                          parseFloat(
                            (
                              (currentCreditorAmountDueInclInterest -
                                payoutsTotal -
                                (latestDebtCollectorTax?.amount
                                  ? 0
                                  : debtistTaxTotal)) *
                              (sliderFactorCreditor / 100)
                            ).toFixed(2)
                          ),
                          0
                        )
                      )}
                      <Tooltip title="Copy amount">
                        <FileCopyOutlined
                          style={{
                            height: 13,
                            cursor: "copy",
                            color: "var(--black10)",
                            padding: 3,
                            verticalAlign: "middle",
                            zIndex: 3,
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigator.clipboard.writeText(
                              numberFormat.format(
                                Math.max(
                                  parseFloat(
                                    (
                                      (currentCreditorAmountDueInclInterest -
                                        payoutsTotal -
                                        (latestDebtCollectorTax?.amount
                                          ? 0
                                          : debtistTaxTotal)) *
                                      (sliderFactorCreditor / 100)
                                    ).toFixed(2)
                                  ),
                                  0
                                )
                              )
                            );
                            notify("Copied to clipboard", "info", null, false);
                          }}
                        />
                      </Tooltip>
                    </Typography>
                  </div>
                )}
                <hr />
              </>
            )}

            {latestDunningCostFee &&
            latestDunningCostFee.amount &&
            latestDunningCostExpense &&
            latestDunningCostExpense.amount ? (
              <Accordion
                variant="outlined"
                square
                style={{
                  border: 0,
                  background: "transparent",
                  padding: 0,
                  margin: 0,
                }}
                expanded={showDebtCollectorFeeAndExpenses}
                onClick={(e) => {
                  if (!showDebtCollectorFeeAndExpenses) {
                    e.stopPropagation();
                  }
                }}
                onChange={(e) => {
                  setShowDebtCollectorFeeAndExpenses(
                    !showDebtCollectorFeeAndExpenses
                  );
                }}
              >
                <AccordionSummary
                  style={{
                    padding: 0,
                    margin: 0,
                    userSelect: "text",
                    wordBreak: "break-word",
                    display: "inline-block",
                    whiteSpace: "pre-line",
                  }}
                  onClick={(e) => {
                    if (!showDebtCollectorFeeAndExpenses) {
                      e.stopPropagation();
                    }
                  }}
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <div className="flex-between">
                    <Typography variant="body2">RVG + Auslagen: </Typography>
                    <Typography variant="body2">
                      {numberFormatEUR.format(
                        parseFloat(
                          (
                            (latestDunningCostFee.amount +
                              latestDunningCostExpense.amount) *
                            (sliderFactorDebtCollector / 100)
                          ).toFixed(2)
                        )
                      )}
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="flex-between">
                    <Typography variant="body2">RVG Gebühr: </Typography>
                    <Typography variant="body2">
                      {numberFormatEUR.format(
                        parseFloat(
                          (
                            latestDunningCostFee.amount *
                            (sliderFactorDebtCollector / 100)
                          ).toFixed(2)
                        )
                      )}
                    </Typography>
                  </div>
                  <div className="flex-between">
                    <Typography variant="body2">RVG Auslagen: </Typography>
                    <Typography variant="body2">
                      {numberFormatEUR.format(
                        parseFloat(
                          (
                            latestDunningCostExpense.amount *
                            (sliderFactorDebtCollector / 100)
                          ).toFixed(2)
                        )
                      )}
                    </Typography>
                  </div>
                </AccordionDetails>
              </Accordion>
            ) : (
              <>
                {latestDunningCostFee && (
                  <div className="flex-between">
                    <Typography variant="body2">RVG Gebühr:</Typography>
                    <Typography variant="body2">
                      {numberFormatEUR.format(
                        parseFloat(
                          (
                            latestDunningCostFee.amount *
                            (sliderFactorDebtCollector / 100)
                          ).toFixed(2)
                        )
                      )}
                    </Typography>
                  </div>
                )}
                {latestDunningCostExpense && (
                  <div className="flex-between">
                    <Typography variant="body2">RVG Auslagen: </Typography>
                    <Typography variant="body2">
                      {numberFormatEUR.format(
                        parseFloat(
                          (
                            latestDunningCostExpense.amount *
                            (sliderFactorDebtCollector / 100)
                          ).toFixed(2)
                        )
                      )}
                    </Typography>
                  </div>
                )}
              </>
            )}
            {latestDefaultInterest && (
              <div className="flex-between">
                <Typography variant="body2">
                  Zinsen{" "}
                  <span style={{ color: theme.palette.grey[500] }}>
                    ab {format(new Date(record.dueDate), "dd.MM.yy")}
                  </span>
                  :{" "}
                </Typography>
                <Typography variant="body2">
                  {numberFormatEUR.format(
                    parseFloat(
                      (
                        latestDefaultInterest.amount *
                        (sliderFactorDebtCollector / 100)
                      ).toFixed(2)
                    )
                  )}
                </Typography>
              </div>
            )}
            {latestCreditorExtra && (
              <div className="flex-between">
                <Typography variant="body2">Mahnkosten Mandant: </Typography>
                <Typography variant="body2">
                  {numberFormatEUR.format(
                    parseFloat(
                      (
                        latestCreditorExtra.amount *
                        (sliderFactorDebtCollector / 100)
                      ).toFixed(2)
                    )
                  )}
                </Typography>
              </div>
            )}
            {paymentDataLoaded &&
              record?.currency &&
              sliderFactorDebtCollector === 100 &&
              record?.currency !== "EUR" && (
                <>
                  <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                  <Typography variant="body2">
                    ={" "}
                    {numberFormat.format(
                      record?.totalPending +
                        record?.totalPaid -
                        record?.originalAmountDue
                    )}{" "}
                    Nebenforderung
                  </Typography>
                </>
              )}
            {latestDebtCollectorTax && (
              <div className="flex-between">
                <Typography variant="body2">Steuer, Debtist:</Typography>
                <Typography variant="body2">
                  {numberFormatEUR.format(
                    parseFloat(
                      (
                        latestDebtCollectorTax.amount *
                        (sliderFactorDebtCollector / 100)
                      ).toFixed(2)
                    )
                  )}
                </Typography>
              </div>
            )}
            {incomingPaymentsTotal !== 0 && (
              <div className="flex-between">
                <Typography variant="body2" style={{ color: "green" }}>
                  Zahlungen erhalten:
                </Typography>

                <Typography variant="body2" style={{ color: "green" }}>
                  {numberFormatEUR.format(incomingPaymentsTotal)}
                </Typography>
              </div>
            )}
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            <div className="flex-between">
              <Typography
                variant="body2"
                style={{
                  fontWeight: 600,
                  fontSize: 16,
                  ...(isPaid && {
                    color: "green",
                  }),
                }}
              >
                Gesamt:{" "}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  fontWeight: 600,
                  fontSize: 16,
                  ...(isPaid && {
                    color: "green",
                  }),
                }}
              >
                <Tooltip title="Copy amount">
                  <FileCopyOutlined
                    style={{
                      height: 15,
                      cursor: "copy",
                      color: "var(--black10)",
                      padding: 3,
                      verticalAlign: "middle",
                      zIndex: 3,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(
                        paymentDataLoaded &&
                          (record?.currency && record?.currency !== "EUR"
                            ? numberFormat.format(record?.totalPending)
                            : numberFormat.format(currentTotal))
                      );
                      notify("Copied to clipboard", "info", null, false);
                    }}
                  />
                </Tooltip>
                {paymentDataLoaded &&
                  (record?.currency && record?.currency !== "EUR"
                    ? numberFormat.format(record?.totalPending)
                    : numberFormat.format(currentTotal))}
                {paymentDataLoaded &&
                  currentTotal !==
                    parseFloat(record?.totalPending?.toFixed(2)) &&
                  currentTotal >= 0 &&
                  sliderFactorCreditor === 100 &&
                  sliderFactorDebtCollector === 100 &&
                  record?.currency &&
                  record?.currency === "EUR" && (
                    <Warning
                      titleAccess="Betrag evtl. inkonsistent"
                      style={{
                        position: "relative",
                        top: 3,
                        height: 16,
                        color: theme.palette.error.main,
                      }}
                    />
                  )}
              </Typography>
            </div>
          </CardContent>
        </Card>
      )}

      <PaymentDataCard
        isAvailableForSuccessFee={isAvailableForSuccessFee}
        paymentDataLoaded={paymentDataLoaded}
        payments={payments}
        payoutsTotal={payoutsTotal}
        incomingPaymentsTotal={incomingPaymentsTotal}
        latestDunningCostExpense={latestDunningCostExpense}
        deductDunningCostFeeFromPendingPayout={
          deductDunningCostFeeFromPendingPayout
        }
        deductDunningCostExpenseFromPendingPayout={
          deductDunningCostExpenseFromPendingPayout
        }
        record={record}
        successFeePercentage={successFeePercentage}
        latestDefaultInterest={latestDefaultInterest}
        debtistCostsTotal={debtistCostsTotal}
        deductDefaultInterestFromPendingPayout={
          deductDefaultInterestFromPendingPayout
        }
        deductExistingCreditorExtrasFromPendingPayout={
          deductExistingCreditorExtrasFromPendingPayout
        }
        toBePaidOutTotal={toBePaidOutTotal}
        creditor={creditor}
        negativePayouts={negativePayouts}
        latestCreditorExtra={latestCreditorExtra}
        debtistCostsPending={debtistCostsPending}
        paymentsBeforeLastPayoutTotal={paymentsBeforeLastPayoutTotal}
        debtistTaxTotal={debtistTaxTotal}
        debtistTaxPending={debtistTaxPending}
        debtCollectorFeesTotal={debtCollectorFeesTotal}
        successFeeTax={successFeeTax}
        relevantSuccessFee={relevantSuccessFee}
      />

      <AdditionalCostCard
        paymentDataLoaded={paymentDataLoaded}
        record={record}
        payments={payments}
        creditorAddress={creditorAddress}
        creditor={creditor}
      />
    </Box>
  );
};

const ClaimEditActions = (props: any) => {
  const record = useRecordContext();

  return (
    <div
      style={{
        marginBottom: 20,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <Tooltip title="Edit Claim" placement="bottom">
          <EditButton
            basePath={"/Claim"}
            record={record}
            label={"Edit"}
            style={{
              margin: 0,
              marginRight: 0,
              minWidth: "100%",
            }}
          />
        </Tooltip>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Tooltip title="View payment page" placement="bottom">
          <Button
            style={{
              marginRight: 0,
              minWidth: "30%",
              padding: "8px 16px",
            }}
            variant={"outlined"}
            size={"medium"}
            href={
              process.env.REACT_APP_DASHBOARD_PAGE_URL +
              "/pay/" +
              record?.id +
              "/" +
              record?.reference
            }
            startIcon={<VisibilityOutlined />}
            // @ts-ignore
            target="_blank"
            rel="noreferrer"
          />
        </Tooltip>

        <Tooltip title="View Tickets" placement="bottom">
          <Button
            style={{
              marginRight: 0,
              minWidth: "30%",
              padding: "8px 16px",
            }}
            variant={"outlined"}
            size={"medium"}
            onClick={() => {
              window.open(
                'https://debtistgmbh.freshdesk.com/a/search/tickets?filter[]=group_id%3A"103000276855%2C103000415785"&sortBy=relevance&term=' +
                  record?.reference,
                "_blank"
              );
            }}
            startIcon={<ConfirmationNumber />}
            label=""
          />
        </Tooltip>

        <Tooltip title="Show Settlement Calculator" placement="bottom">
          <Button
            style={{
              marginRight: 0,
              minWidth: "30%",
              padding: "8px 16px",
            }}
            variant={"outlined"}
            size={"medium"}
            onClick={() => {
              props.setShowFactorCalculator(!props.showFactorCalculator);
            }}
            startIcon={<LocalOfferOutlined />}
            label=""
          />
        </Tooltip>
      </div>
    </div>
  );
};
