// eslint-disable-next-line import/no-anonymous-default-export
export default {
  bank: {
    accountHolder: "Debtist GmbH",
    iban: "DE88100400000250314201",
    bic: "COBADEFFXXX",
    companyAccount: {
      accountHolder: "Debtist GmbH",
      iban: "DE18100400000250314200",
      bic: "COBADEFFXXX",
    },
  },
};

export const TOOL_MANAGERS = [
  "admin",
  "b.baumgarten@debtist.de",
  "m.benedetti@debtist.de",
  "t.zabel@debtist.de",
  "k.nelson@debtist.de",
];

export const OPS_MANAGERS = [
  "admin",
  "b.baumgarten@debtist.de",
  "m.benedetti@debtist.de",
  "t.zabel@debtist.de",
  "b.morgado@debtist.de",
  "s.jung@debtist.de",
  "k.nelson@debtist.de",
];

export const OPS_ELEVATED_RIGHT_USERS = [
  "admin",
  "b.baumgarten@debtist.de",
  "m.benedetti@debtist.de",
  "t.zabel@debtist.de",
  "k.nelson@debtist.de",
  "s.jung@debtist.de",
  "a.freitag@debtist.de",
  "l.giles@debtist.de",
];

export const FINANCE_MANAGERS = [
  "admin",
  "b.baumgarten@debtist.de",
  "m.benedetti@debtist.de",
  "t.zabel@debtist.de",
  "b.morgado@debtist.de",
  "s.jung@debtist.de",
  "n.hazrati@debtist.de",
  "l.zimmermann@debtist.de",
  "k.nelson@debtist.de",
  "a.freitag@debtist.de",
];

export const VIP_USERS = ["b.baumgarten@debtist.de", "m.benedetti@debtist.de"];
