import {
  Button,
  Card,
  CardContent,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Switch,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Title, useGetIdentity, useNotify } from "react-admin";
import ReactSimplyCarousel from "react-simply-carousel";

import EventNoteIcon from "@material-ui/icons/EventNote";
import ExploreIcon from "@material-ui/icons/Explore";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import GavelIcon from "@material-ui/icons/Gavel";
import HelpOutlinedIcon from "@material-ui/icons/HelpOutlined";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";

import { PhoneOutlined, Timelapse } from "@material-ui/icons";
import { format } from "date-fns";
import { ClaimSummary } from "../Components/ClaimSummary";
import AircallPhoneComponent from "../Components/aircall/AirCallPhone";
import { getActivityTypeIcon } from "../Components/icon/ActivityTypeIcon";
import ClaimsToDoTab from "../Components/tabs/dashboard/ClaimsToDoTab";
import InsolvencyActivityToDoTab from "../Components/tabs/dashboard/InsolvencyActivityToDoTab";
import JudicialActivityToDoTab from "../Components/tabs/dashboard/JudicialActivityToDoTab";
import OtherToDoTab from "../Components/tabs/dashboard/OtherToDoTab";
import PlannedCallsToDoTab from "../Components/tabs/dashboard/PlannedCallsToDoTab";
import ResearchToDoTab from "../Components/tabs/dashboard/ResearchToDoTab";
import WaitingForCreditorToDoTab from "../Components/tabs/dashboard/WaitingForCreditorToDoTab";
import { Activity, ActivityTypes } from "../api/activity/Activity";
import { normalizePhoneNumber } from "../api/address/Address";
import { Claim } from "../api/claim/Claim";
import { OPS_MANAGERS, TOOL_MANAGERS } from "../config/AppConfig";
import { theme } from "../theme/theme";
import { aircallService } from "../util/AirCallCalls";
import { fetchPlannedCalls } from "../util/FetchCalls";

const today: Date = new Date();
today.setHours(23, 59, 59, 59);

const TIME_ZONES: string[] = [
  "Europe/Berlin",
  "America/New_York",
  "America/Los_Angeles",
  "Europe/London",
  "Asia/Tokyo",
  "Asia/Shanghai",
  "Asia/Hong_Kong",
  "Africa/Johannesburg",
  "America/Bogota",
];

const Dashboard = React.memo(() => {
  const hidePhoneStats: boolean = true;
  const phoneRef = useRef(null);
  const [showAirCallPhone, setShowAirCallPhone] = useState(false);
  const [callsLoaded, setCallsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAssignedClaims, setIsLoadingAssignedClaims] = useState(false);
  const [personalization, setPersonalization] = useState(true);
  const [enableLegal, setEnableLegal] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [parentTabValue, setParentTabValue] = React.useState(0);
  const [totalPlannedCalls, setTotalPlannedCalls] = useState(0);
  const [totalWaiting, setTotalWaiting] = useState("0");
  const [totalOther, setTotalOther] = useState(0);
  const [totalResearch, setTotalResearch] = useState(0);
  const [totalJudicialActivity, setTotalJudicialActivity] = useState(0);
  const [totalInsolvencyActivity, setTotalInsolvencyActivity] = useState(0);
  const [totalWaitingForCreditor, setTotalWaitingForCreditor] = useState(0);
  const [loadedAssignedClaims, setLoadedAssignedClaims] = useState([]);
  const [timeOfDayGreeting, setTimeOfDayGreeting] = useState<
    "Good morning" | "Good afternoon" | "Good evening"
  >("Good morning");
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [countFlagClicks, setCountFlagClicks] = useState(0);
  const [nextFlight, setNextFlight] = useState(false);

  const [assignedPhoneList, setAssignedPhoneList] = useState<string[]>([]);
  const [plannedCallList, setPlannedCallList] = useState<string[]>([]);

  const [isScrolledPastMainTabs, setIsScrolledPastMainTabs] = useState(false);
  const mainTabsRef = useRef<HTMLDivElement>(null);

  const notify = useNotify();

  const handleChange = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      setTabValue(newValue);
    },
    [setTabValue]
  );

  const handleParentTabChange = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      setParentTabValue(newValue);
    },
    [setParentTabValue]
  );

  const { identity } = useGetIdentity();

  const fetchAssignedClaims = useCallback(async () => {
    if (
      !identity?.id ||
      isLoadingAssignedClaims ||
      loadedAssignedClaims?.length
    ) {
      return;
    }

    setIsLoadingAssignedClaims(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/users/${identity.id}`,
        {
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("credentials") || "",
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch user: ${response.status}`);
      }

      const userData = await response.json();
      if (!userData.claimsAsAssignee) {
        setIsLoadingAssignedClaims(false);
      }
      setLoadedAssignedClaims(userData.claimsAsAssignee || []);
    } catch (error) {
      console.error("Error fetching assigned claims:", error);
    } finally {
      setIsLoadingAssignedClaims(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identity?.id]);

  useEffect(() => {
    if (loadedAssignedClaims.length === 0 && !isLoading) {
      fetchAssignedClaims();
    }
  }, [fetchAssignedClaims, loadedAssignedClaims.length, isLoading]);

  const updateTimeOfDay: () => void = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 5 && currentHour < 12) {
      setTimeOfDayGreeting("Good morning");
    } else if (currentHour >= 12 && currentHour < 18) {
      setTimeOfDayGreeting("Good afternoon");
    } else {
      setTimeOfDayGreeting("Good evening");
    }
  };

  useEffect(() => {
    updateTimeOfDay(); // Initial update
    const intervalId = setInterval(updateTimeOfDay, 60000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (countFlagClicks === 3) {
      setNextFlight(true);
      setCountFlagClicks(0);

      setTimeout(() => {
        setNextFlight(false);
      }, 2000);
    }
  }, [countFlagClicks, setCountFlagClicks, setNextFlight]);

  const fetchPlannedCallsData = useCallback(async () => {
    if (!process.env.REACT_APP_SERVER_URL) {
      console.error("Server URL is not defined");
      return;
    }

    setIsLoading(true);
    try {
      const result = await fetchPlannedCalls(process.env.REACT_APP_SERVER_URL, {
        personalization,
        enableLegal: false,
        languageFilter: "",
        userTypeFilter: "",
        activeSearchTerm: "",
        claimsSortField: "updatedAt",
        claimsSortOrder: "asc",
      });

      if (result && result.total) {
        setTotalPlannedCalls(result.total);
        setPlannedCallList(result.results?.map((claim) => claim.debtor.phone));
        setCallsLoaded(true);
      }
    } catch (error) {
      console.error("Error fetching planned calls:", error);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePlannedCallCampaign = () => {
    if (!plannedCallList || plannedCallList.length === 0) {
      notify("Currently no planned Claims", "warning");
    }

    setAssignedPhoneList(
      loadedAssignedClaims.map((claim) => claim.debtor.phone)
    );

    phoneRef.current?.send("dial_number", plannedCallList, (success, data) => {
      if (success) {
        notify("Dialing Call", "success");
      } else {
        notify("Could not dial", "warning");
      }
    });

    if (assignedPhoneList) {
      aircallService.createPlannedCallsCampaign(
        identity?.fullName,
        identity?.id,
        plannedCallList
      );
      setShowAirCallPhone(true);
    }
  };

  const handleAssignedCallCampaign = () => {
    if (!loadedAssignedClaims) {
      notify("Currently no assigned Claims", "warning");
    }
    setAssignedPhoneList(
      loadedAssignedClaims.map((claim) => claim.debtor.phone)
    );

    phoneRef.current?.send(
      "dial_number",
      normalizePhoneNumber(assignedPhoneList[0].toString()),
      (success, data) => {
        if (success) {
          notify("Dialing Call", "success");
        } else {
          notify("Could not dial", "warning");
        }
      }
    );

    if (assignedPhoneList) {
      aircallService.createAssignedCallsCampaign(
        identity?.fullName,
        identity?.id,
        assignedPhoneList
      );
      setShowAirCallPhone(true);
    }
  };

  useEffect(() => {
    if (isLoading) {
      fetchPlannedCallsData();
    }
  }, [fetchPlannedCallsData, isLoading]);

  useEffect(() => {
    const handleScroll = () => {
      if (mainTabsRef.current) {
        const mainTabsOffsetTop = mainTabsRef.current.offsetTop;
        const scrollPosition = window.scrollY;
        if (scrollPosition > mainTabsOffsetTop) {
          setIsScrolledPastMainTabs(true);
        } else {
          setIsScrolledPastMainTabs(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrolledPastMainTabs]);

  return (
    <div>
      <Title title="Dashboard" />
      <Grid container spacing={2} alignItems={"flex-start"}>
        <Grid xs={12} md={8} item>
          <Tabs
            onChange={handleParentTabChange}
            value={parentTabValue}
            aria-label={"dashboard main tabs"}
            variant={"scrollable"}
            scrollButtons={"auto"}
          >
            <Tab
              label={`${
                identity?.fullName?.includes(" ")
                  ? identity?.fullName?.split(" ")[0] + "'s "
                  : identity?.fullName + "'s "
              } Dashboard`}
            />
            <Tab
              label={"Meine Akten"}
              disabled={loadedAssignedClaims.length < 1}
            />
            {TOOL_MANAGERS.includes(identity?.username) && !hidePhoneStats && (
              <Tab label={"Nerd Aircall Stats 🤓"} />
            )}
          </Tabs>
        </Grid>

        <Grid xs={12} md={4} item className="personalized">
          <FormControlLabel
            label="Legal Mode"
            value={enableLegal}
            onChange={(_e, checked) => {
              setEnableLegal(checked);
            }}
            disabled={isLoading}
            control={<Switch color="secondary" defaultChecked={enableLegal} />}
            labelPlacement={"start"}
          />
          <FormControlLabel
            label="Personalized"
            value={personalization}
            onChange={(_e, checked) => {
              setPersonalization(checked);
            }}
            disabled={isLoading}
            control={
              <Switch color="secondary" defaultChecked={personalization} />
            }
            labelPlacement={"start"}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        alignItems={"flex-start"}
        style={{
          marginTop: 10,
        }}
      >
        <Grid xs={12} md={3} item>
          <Card variant="outlined" title="Time Zones">
            <CardContent
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor:
                  timeOfDayGreeting === "Good evening" ? "#2f2e40" : "#fff",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  color:
                    timeOfDayGreeting === "Good evening"
                      ? "#fff"
                      : "rgba(0, 0, 0, 0.87)",
                }}
              >{`${timeOfDayGreeting} ${
                identity?.fullName?.includes(" ")
                  ? identity?.fullName?.split(" ")[0]
                  : identity?.fullName
              } ${
                timeOfDayGreeting === "Good evening" ? "🌙" : "🌞"
              }`}</Typography>
            </CardContent>
            <Divider />
            <CardContent
              className="flex-center relative"
              onClick={() => setCountFlagClicks(countFlagClicks + 1)}
            >
              <ReactSimplyCarousel
                activeSlideIndex={activeSlideIndex}
                itemsToScroll={1}
                itemsToShow={1}
                onRequestChange={setActiveSlideIndex}
                forwardBtnProps={{
                  style: {
                    display: "none",
                  },
                }}
                backwardBtnProps={{
                  style: {
                    display: "none",
                  },
                }}
                containerProps={{
                  style: {
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    userSelect: "none",
                  },
                }}
                autoplayDelay={5000}
                speed={300}
                autoplay
              >
                {TIME_ZONES.map((tz) => (
                  <div
                    id={tz}
                    className="tab-col flex-center"
                    style={{
                      minWidth: 200,
                    }}
                  >
                    <Typography variant="subtitle2" className="flex-center">
                      {`${tz.split("/")[1].replace("_", " ")} ${
                        tz.includes("Berlin")
                          ? "🇩🇪"
                          : tz.includes("Bogota")
                          ? "🇨🇴"
                          : tz.includes("America")
                          ? "🇺🇸"
                          : tz.includes("London")
                          ? "🇬🇧"
                          : tz.includes("Tokyo")
                          ? "🇯🇵"
                          : tz.includes("Johannesburg")
                          ? "🇿🇦"
                          : tz.includes("Shanghai") || tz.includes("Hong_Kong")
                          ? "🇨🇳"
                          : ""
                      }`}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className="flex-center"
                      style={{
                        fontWeight: "bold",
                        color: "var(--primary)",
                      }}
                    >
                      {`${new Intl.DateTimeFormat("de-DE", {
                        timeZone: tz,
                        hour: "2-digit",
                        minute: "2-digit",
                      }).format(new Date())}`}
                    </Typography>
                  </div>
                ))}
              </ReactSimplyCarousel>

              {nextFlight && (
                <div className="next-flight" style={{ height: 20 }}>
                  {"🛩️"}
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid xs={12} md={3} item>
          <Card variant="outlined" style={{ minHeight: "100%" }}>
            <CardContent className="flex-center">
              <Typography variant="subtitle2" className="flex-center">
                {totalPlannedCalls <= 0 || totalPlannedCalls === undefined
                  ? "Keine geplanten Akten"
                  : `Geplante Akten: ${totalPlannedCalls}`}
                {OPS_MANAGERS.includes(identity?.username) && (
                  <Tooltip title={"Start: Geplante Calls"}>
                    <Button
                      startIcon={<PhoneOutlined />}
                      size={"medium"}
                      type={"button"}
                      color={"primary"}
                      variant={"contained"}
                      onClick={handlePlannedCallCampaign}
                      style={{
                        marginLeft: 10,
                        width: 30,
                        height: 30,
                        minWidth: 30,
                        borderRadius: 100,
                      }}
                    ></Button>
                  </Tooltip>
                )}
              </Typography>
            </CardContent>
            <Divider />
            <CardContent className="flex-center">
              <Typography variant="subtitle2" className="flex-center">
                {loadedAssignedClaims.length === 0
                  ? "Keine zugewiesenen Akten"
                  : `Zugewiesene Akten: ${loadedAssignedClaims.length}`}
                {loadedAssignedClaims.length > 0 &&
                  OPS_MANAGERS.includes(identity?.username) && (
                    <Tooltip title={"Start: Zugewiesene Calls"}>
                      <Button
                        startIcon={<PhoneOutlined />}
                        size={"medium"}
                        type={"button"}
                        color={"primary"}
                        variant={"contained"}
                        onClick={handleAssignedCallCampaign}
                        style={{
                          marginLeft: 10,
                          width: 30,
                          height: 30,
                          minWidth: 30,
                          borderRadius: 100,
                        }}
                      ></Button>
                    </Tooltip>
                  )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} md={3} item>
          <div
            style={{
              position:
                isScrolledPastMainTabs && showAirCallPhone
                  ? "fixed"
                  : "relative",
              zIndex: isScrolledPastMainTabs && showAirCallPhone ? 9999 : 1,
              right: isScrolledPastMainTabs && showAirCallPhone ? 170 : 0,
              top: isScrolledPastMainTabs && showAirCallPhone ? 60 : 0,
            }}
          >
            <AircallPhoneComponent
              phoneRef={phoneRef}
              showAirCallPhone={showAirCallPhone}
              setShowAirCallPhone={setShowAirCallPhone}
            />
          </div>
        </Grid>
        <Grid xs={12} md={3} item>
          <Automations />
        </Grid>
      </Grid>

      <div
        role="tabpanel"
        hidden={parentTabValue !== 0}
        id={`parent-tabpanel-${0}`}
        ref={mainTabsRef}
      >
        <Tabs
          onChange={handleChange}
          value={tabValue}
          aria-label={"dashboard tabs"}
          style={{
            marginTop: 25,
            marginBottom: 15,
            background: "rgba(255, 255, 255, 0.5)",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "rgba(0, 0, 0, 0.12)",
            borderRadius: 8,
            maxWidth: "100%",
          }}
          variant={"scrollable"}
          scrollButtons={"auto"}
        >
          <Tab
            label={
              "Calls" +
              (callsLoaded === true && tabValue === 0 && totalPlannedCalls > 0
                ? ` (${totalPlannedCalls})`
                : "")
            }
            icon={<EventNoteIcon />}
          />
          <Tab
            label={
              "Waiting" +
              (tabValue === 1 && Number(totalWaiting.replaceAll("+", "")) > 0
                ? ` (${totalWaiting})`
                : "")
            }
            icon={<FormatListBulletedIcon />}
          />
          <Tab
            label={
              `Other` +
              (tabValue === 2 && totalOther > 0 ? ` (${totalOther})` : "")
            }
            icon={<HelpOutlinedIcon />}
          />
          <Tab
            label={
              `Research` +
              (tabValue === 3 && totalResearch > 0 ? ` (${totalResearch})` : "")
            }
            icon={<ExploreIcon />}
          />
          <Tab
            label={
              `Judicial activity` +
              (tabValue === 4 && totalJudicialActivity > 0
                ? ` (${totalJudicialActivity})`
                : "")
            }
            icon={<GavelIcon />}
          />
          <Tab
            label={
              `Insolvency activity` +
              (tabValue === 5 && totalInsolvencyActivity > 0
                ? ` (${totalInsolvencyActivity})`
                : "")
            }
            icon={<MoneyOffIcon />}
          />
          <Tab
            label={
              `Waiting for Creditor` +
              (tabValue === 6 && totalWaitingForCreditor > 0
                ? ` (${totalWaitingForCreditor})`
                : "")
            }
            icon={<Timelapse />}
          />
        </Tabs>

        <PlannedCallsToDoTab
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          personalization={personalization}
          enableLegal={enableLegal}
          setCallsLoaded={setCallsLoaded}
          setTotalPlannedCalls={setTotalPlannedCalls}
          tabValue={tabValue}
        />

        <ClaimsToDoTab
          personalization={personalization}
          enableLegal={enableLegal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setTotalWaiting={setTotalWaiting}
          tabValue={tabValue}
        />

        <OtherToDoTab
          personalization={personalization}
          enableLegal={enableLegal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setTotalOther={setTotalOther}
          tabValue={tabValue}
        />

        <ResearchToDoTab
          personalization={personalization}
          enableLegal={enableLegal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setTotalResearch={setTotalResearch}
          tabValue={tabValue}
        />

        <JudicialActivityToDoTab
          personalization={personalization}
          enableLegal={enableLegal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setTotalJudicialActivity={setTotalJudicialActivity}
          tabValue={tabValue}
        />

        <InsolvencyActivityToDoTab
          personalization={personalization}
          enableLegal={enableLegal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setTotalInsolvencyActivity={setTotalInsolvencyActivity}
          tabValue={tabValue}
        />

        <WaitingForCreditorToDoTab
          personalization={personalization}
          enableLegal={enableLegal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setTotalWaitingForCreditor={setTotalWaitingForCreditor}
          tabValue={tabValue}
        />
      </div>

      <div
        role="tabpanel"
        hidden={parentTabValue !== 1}
        id={`parent-tabpanel-${1}`}
        style={{ marginTop: 20 }}
      >
        {loadedAssignedClaims && loadedAssignedClaims.length > 0
          ? loadedAssignedClaims.map((claim: Claim) => {
              return (
                <ClaimSummary
                  claim={claim}
                  type={"claim"}
                  key={claim.id}
                  phoneRef={phoneRef}
                  setShowAirCallPhone={setShowAirCallPhone || (() => {})}
                  refresh={() => {
                    fetchAssignedClaims();
                  }}
                />
              );
            })
          : "-"}
      </div>

      <div
        role="tabpanel"
        hidden={parentTabValue !== 2}
        id={`parent-tabpanel-${2}`}
        style={{ marginTop: 20 }}
      >
        <Typography variant="h5">{"Team Aircall Stats"}</Typography>
        <Grid container spacing={2} style={{ marginTop: 10 }}>
          <Typography>{"Stats coming soon"}</Typography>
        </Grid>
      </div>

      <div
        className={`aircallPhone ${showAirCallPhone ? "-open" : ""}`}
        id="phone"
      ></div>
    </div>
  );
});

const Automations: React.MemoExoticComponent<() => JSX.Element> = React.memo(
  () => {
    const [overdueAutomations, setOverdueAutomations] = useState([]);
    const [showDetails, setShowDetails] = useState(false);

    useEffect(() => {
      const authHeader = {
        headers: {
          Authorization: localStorage.getItem("credentials") || "",
          "Content-Type": "application/json",
        },
      };

      fetch(process.env.REACT_APP_SERVER_URL + "/api/activities/cron/monitor", {
        ...authHeader,
      })
        .then((response) => response.json())
        .then((parsedResponse) => {
          if (parsedResponse.length) {
            setOverdueAutomations(parsedResponse);
          }
        });
    }, []);

    return (
      <>
        <Card
          variant="outlined"
          style={{
            background:
              overdueAutomations && overdueAutomations.length > 0
                ? overdueAutomations.length > 100
                  ? theme.palette.error.main
                  : theme.palette.warning.main
                : theme.palette.success.main,
          }}
        >
          <CardContent className="flex-center" style={{ minHeight: 20 }}>
            <Typography variant="subtitle2">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setShowDetails(!showDetails);
                }}
                href={`/#/Activity?displayedFilters=%7B%7D&filter=%7B"isPlanned"%3Atrue%2C"activityDate"%3A"${
                  today.toISOString().split("T")[0]
                }"%7D`}
                style={{
                  color: "white",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
              >
                Wartende Automations:{" "}
                <span style={{ fontWeight: "bold" }}>
                  {overdueAutomations?.length &&
                  overdueAutomations?.length > 100
                    ? "🔥 "
                    : ""}
                  {overdueAutomations?.length ? overdueAutomations.length : "0"}
                  {overdueAutomations?.length &&
                  overdueAutomations?.length > 100
                    ? " 🔥"
                    : ""}
                </span>
              </a>
            </Typography>
          </CardContent>
        </Card>
        {showDetails && (
          <Card>
            <List
              style={{
                maxHeight: 300,
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              {overdueAutomations &&
                overdueAutomations.map((activity: Activity, key) => {
                  return (
                    <a
                      href={"#/Activity/" + activity.id + "/show"}
                      style={{
                        textDecoration: "none",
                        color: theme.palette.text.primary,
                      }}
                    >
                      <ListItem
                        key={activity.id}
                        id={activity.id}
                        className="list-item-activity"
                      >
                        {getActivityTypeIcon(
                          activity.activityType as ActivityTypes
                        )}
                        <div className="col">
                          <span className="date">{`${format(
                            activity.activityDate,
                            "dd.MM.yyyy"
                          )}: `}</span>

                          <span className="reference">
                            {activity.claim?.reference}
                          </span>
                          <span className="company">
                            {activity.claim?.creditor?.businessName}
                          </span>
                        </div>
                      </ListItem>
                      {overdueAutomations.length - 1 !== key && <Divider />}
                    </a>
                  );
                })}
            </List>
          </Card>
        )}
      </>
    );
  }
);

export default Dashboard;
